import CanvasJSReact from '@canvasjs/react-charts';
import Card from 'react-bootstrap/Card';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Graph(props){

  let graphType = props.graphType;

  if (props.data){
    let options = {
      theme: "dark2",
      title: {
        text: props.title,
        fontSize: 20
      },
      axisY: {
        includeZero: false,
      },
      axisX: {
        interval: 1
      },
      data: [{
        type: graphType,
        yValueFormatString: "#,### Students",
        dataPoints: props.data
      }]
    }

    if (graphType === "pie"){
      options = {
        theme: "dark2",
        title: {
          text: props.title,
          fontSize: 20
        },
        data: [{
          type: "pie",
          startAngle: 60,
          //innerRadius: 60,
          indexLabelFontSize: 17,
          indexLabel: "{label} - #percent%",
          toolTipContent: "<b>{label}:</b> {y} (#percent%)",
          dataPoints: props.data
        }]
      }
    }
    
    else if (graphType === "multiCol"){
      options = {
        theme: "dark2",
        title: {
          text: props.title,
          fontSize: 20
        },	
        toolTip: {
          shared: true
        },
        axisX: {
          interval: 1
        },
        data: [{
          type: "column",
          legendText: props.colText1,
          showInLegend: true, 
          dataPoints:props.data
        },
        {
          type: "column",	
          legendText: props.colText2,
          showInLegend: true,
          dataPoints:props.data1
        }]
      }
    }

    else if (graphType === "barChart") {
      options = {
        theme: "dark2",
        title: {
          text: props.title,
          fontSize: 20
        },
        toolTip: {
          shared: true
        },
        axisY: {
          title: props.title1,
          interval: props.interval,
          labelFontSize: 14,
          titleFontSize: 16
        },
        axisX: {
          title: props.title2,
          interval: 1,
          labelFormatter: function (e) {
            return e.label;
          },
          labelFontSize: 14,
          titleFontSize: 16
        },
        data: [{
          type: "bar",
          showInLegend: false,
          indexLabelFontWeight: "bold",
          dataPoints: props.data.map(dataPoint => ({
            ...dataPoint,
            indexLabel: String(dataPoint.y), 
            indexLabelPlacement: "outside", 
            indexLabelFontSize: 17
          }))
        }]
      }
    }

    const containerProps = {
        width: "100%",
        height: "100%",
    }
                
    return (
      <Card className="dark" style={{ height: props.height, width: props.width}}>
        <Card.Body>
          <CanvasJSChart options = {options} containerProps = {containerProps} />
        </Card.Body>
      </Card>
    );
  }
}

export default Graph;
