import React, { useState } from 'react';

const CustomSelect = ({id, type, value, data, onChange, onUpdate, isDisabled }) => {
  const [selectValue, setSelectValue] = useState(value);
  
  const handleSelectChange = (e) => {
    setSelectValue(e.target.value);
    onChange(e.target.value);
    onUpdate();
  };
  const keyList = [];
  data.forEach((element, index) => {
    keyList.push(element.key);
  })
  if (keyList.includes(selectValue) || type === 'noOthers'){
    return (
      <select
        id = {id}
        value = {selectValue}
        onChange={handleSelectChange}
        disabled={isDisabled}
      >
        {data.map((element, index) => {
          keyList.push(element.key);
          return (
            <option key={index} value={element.key}>{element.value}</option>
          ) 
        })};
      </select>
    )
  }
  else {
    return (
      <select
        id = {id}
        value = {value}
        onChange={handleSelectChange}
        disabled={isDisabled}
      >
        {data.map((element, index) => {
          keyList.push(element.key);
          return (
            <option key={index} value={element.key}>{element.value}</option>
          ) 
        })};
        <option value={value}>Others</option>
      </select>
    )
  }
};

export default CustomSelect;