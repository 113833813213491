import { useState } from "react";
import '../../src/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { format } from "date-fns";

import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

function DigitalClock(){

    let time  = new Date().toLocaleTimeString('en-US', { hour12: false });
    const [ctime,setTime] = useState(time);
    const UpdateTime=()=>{
      time =  new Date().toLocaleTimeString('en-US', { hour12: false });
      setTime(time);
    }
    
    let now = new Date();
    let day = now.getDay();
    let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let currentDay = daysOfWeek[day];
    let formattedDay = currentDay + ', ' + format(now, "dd MMMM");
    const [dateDay, setDateDay] = useState(formattedDay);
    const UpdateDateDay=()=>{
        now = new Date();
        day = now.getDay();
        currentDay = daysOfWeek[day];
        formattedDay = currentDay + ', ' + format(now, "dd MMMM");
        setDateDay(formattedDay);
    }
    setInterval(UpdateTime);
    setInterval(UpdateDateDay);

    return (
        <div>
            <Card className="dark" style={{width: '90%'}}>
                <Card.Body>
                    <div className="d-flex flex-wrap"> 
                        <div className="col-3" style={{margin:'auto', textAlign:'left'}}>
                            <FontAwesomeIcon color={"white"} icon={faClock} style={{height:'4rem'}}/>
                        </div>
                        <div className="col">
                            <h1 className={'info-1'}>{ctime}</h1>
                            <h1 className={"info-2"}>{dateDay}</h1>
                        </div> 
                    </div>
                </Card.Body> 
            </Card>
        </div>    
    )
}
export default DigitalClock;