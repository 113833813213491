import '../../src/App.css';
import {React, useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import CustomSelect from './CustomSelect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

function ManageRecruiterTable(props){

  const navigate = useNavigate();
  
    let noti = "";
    const notifySuccess = () => toast.success(noti);
    const notifyError = () => toast.error(noti);

    let data = props.tableData; 
    let recruiterHeads = props.recruiterHeads;
    let recruitmentTeams = props.recruitmentTeams;
    let territories = props.territories;
    let countries = props.countries;
    let regions = props.regions;

    const handleUpdate = async() => {
      const dataBody = [];
      data.forEach(element => {
          let recruiterHeadList = recruiterHeads.filter((x)=>{
              return x.key == element.supervisor_id
          }) 
          
          if (recruiterHeadList.length > 0){
            dataBody.push({
              empNo: element.emp_no,
              recruiterCode: element.recruiter_code,
              supervisor: (document.getElementById(`${element.recruiter_code}_head`)).value,
              recruitmentTeamCode: (document.getElementById(`${element.recruiter_code}_team`)).value,
              territoryCode: (document.getElementById(`${element.recruiter_code}_territory`)).value,
              countryCode: (document.getElementById(`${element.recruiter_code}_country`)).value,
              regionCode: (document.getElementById(`${element.recruiter_code}_region`)).value,
              supervisorRecruiterCode: recruiterHeadList[0].recruiter_code
            });
          }     
        });
        try{
          const result = await postData(dataBody);
          if(result.data.success){
            noti = "Successfully Updated!";
            notifySuccess();
          }
          else{
            noti = "Update Failed, Please Inform MIS!";
            notifyError();
          }
        }
        catch{
          noti = "Update Failed, Please Inform MIS!";
          notifyError();
        }
    };

    async function postData(bodyData) {
      const url = process.env.REACT_APP_API + `/updateRecruiterList`;
      const configs = {
        headers:{
          'Authorization': 'Bearer '+localStorage.getItem("token"),
          'Content-Type': 'application/json',   
        }
      };
      try {
        const response = await axios.put(url, {recruiterList:bodyData}, configs);
        return response;
      } catch (error) {
        if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
          Cookies.remove('sessionData');
          localStorage.removeItem('token')
          navigate('/login')
          return;
        }
        console.error("Error:", error);
      }
    }

    /*
    async function postData(data) {
        const url = process.env.REACT_APP_API + `/updateRecruiterList`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({recruiterList:data}),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }
    */

    return (
        <div>
            <ToastContainer />
            <div className='tableWrap' style={{marginTop:'20px'}}>
                <table>
                    <thead>
                        <tr>     
                            <th className="table-header" style={{width:'20%'}}>Recruiter Name</th>
                            <th className="table-header" style={{width:'20%'}}>Email</th>
                            <th className="table-header" style={{width:'20%'}}>Supervisor</th>
                            <th className="table-header" style={{width:'10%'}}>Recruitment Team</th>
                            <th className="table-header" style={{width:'10%'}}>Territory</th>
                            <th className="table-header" style={{width:'10%'}}>Country</th>
                            <th className="table-header" style={{width:'20%'}}>Region</th>
                        </tr>
                    </thead>

                    { <tbody>
                        {data.map((element, index) => {
                            let inputState = "";
                            let recruiterHeadList = recruiterHeads.filter((x)=>{
                                return x.key == element.supervisor_id
                            }) 
                            
                            if (recruiterHeadList.length == 0){
                                inputState = "disabled";
                            }   

                            return (
                                <tr key={index}>
                                    <td className="table-data1" id={element.recruiter_code} style={{textAlign: 'center'}}>{element.recruiter_name}</td>
                                    <td className="table-data">{element.email}</td> 
                                    <td className="table-data">
                                        <CustomSelect type={''} id={element.recruiter_code + '_head'} value={element.supervisor_id} data={recruiterHeads} onChange = {() => {}} onUpdate={() => {}} isDisabled={inputState}/>
                                    </td>        
                                    <td className="table-data">
                                        <CustomSelect type={''} id={element.recruiter_code + '_team'} value={element.recruitment_team_code} data={recruitmentTeams} onChange = {() => {}} onUpdate={() => {}} isDisabled={inputState}/>
                                    </td>      
                                    <td className="table-data">
                                        <CustomSelect type={''} id={element.recruiter_code + '_territory'} value={element.territory} data={territories} onChange = {() => {}} onUpdate={() => {}} isDisabled={inputState}/>
                                    </td>
                                    <td className="table-data">
                                        <CustomSelect type={''} id={element.recruiter_code + '_country'} value={element.country} data={countries} onChange = {() => {}} onUpdate={() => {}} isDisabled={inputState}/>
                                    </td>
                                    <td className="table-data">
                                        <CustomSelect type={''} id={element.recruiter_code + '_region'} value={element.region} data={regions} onChange = {() => {}} onUpdate={() => {}} isDisabled={inputState}/>
                                    </td>
                                </tr>
                            )
                        })} 
                    </tbody> }
                </table>     
            </div>
            <br></br>
            <Button variant="primary" className="ms-auto" onClick={handleUpdate}>Update</Button>
            <br></br>
        </div>      
    )
}
export default ManageRecruiterTable;