import '../../src/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Component, React} from 'react';
import Card from 'react-bootstrap/Card';

class PerformanceCard extends Component {   
   render() {
      const { enrollmentList, title, type } = this.props
      let currYear = new Date().getFullYear();

      return ( 
            <Card className="dark" style={{height: '95%', width: '95%'}}>
                <Card.Body className="p-0" style={{background:'#32373a',margin:'1rem'}}>
                     <div className="p-1">
                        <h1 className={"info-1 text-center"} style={{fontSize:"20px"}}>{title}</h1><br></br>
                        <table className="table table-striped table-dark table-responsive maxWidthNone mt-2"  style={{height:'fit-content', border:"2px solid"}}>
                           <thead>
                              <tr>
                                 <th className="table-header">No.</th>
                                 <th className="table-header">Recruiter's Name</th>
                                 <th className={"table-header"}>Course Code</th>
                                 <th className={type==="enrollment" ? "table-header" : "d-none"}>Enrolled Date</th>
                                 <th className={type==="application" ? "table-header" : "d-none"}>Application Date</th>
                              </tr>
                           </thead>
                           <tbody>
                              { 
                                 enrollmentList.map((x, ind) => {
                                    let date = type === 'enrollment' ? new Date(x.date_enrol) : new Date(x.application_receipt_date);
                                    let day = String(date.getUTCDate()).padStart(2, '0'); 
                                    let month = String(date.getUTCMonth() + 1).padStart(2, '0');
                                    let year = date.getUTCFullYear();
                                    let formattedDate = `${day}-${month}-${year}`; // Format date as "dd-mm-yyyy"
                                    let style = {fontWeight: "600"}
                                    return ind+1 < 6 ? (
                                       <tr className={'font-weight-bold text-center'} style={{fontSize:"1.05rem"}}>
                                          <td style={style}>{ind+1}</td>
                                          <td style={style}>{x.recruiter_name}</td>
                                          <td style={style}>{x.courseCode}</td>
                                          <td style={style}>{formattedDate}</td>
                                       </tr>
                                    ) : '';
                              })}
                              
                           </tbody>
                        </table>
                     </div>
                </Card.Body> 
            </Card>
      );
   }
}

export default PerformanceCard;