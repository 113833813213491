import '../../src/App.css';
import {React} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomSelect from './CustomSelect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

function ManageCourseTable(props){

    const navigate = useNavigate();

    let noti = "";
    const notifySuccess = () => toast.success(noti);
    const notifyError = () => toast.error(noti);

    let data = props.tableData; 
    let courseTypeData = props.courseTypes;

    const handleUpdate = async (course_code) => {
        const courseTypeSelect= (document.getElementById(`${course_code}_type`));
        if (courseTypeSelect){
            try{
                const result = await postData(course_code, courseTypeSelect.value);
                if (result.data.success){
                    noti="Successfully Updated!";
                    notifySuccess();
                }
                else{
                    noti="Update Failed, Please Inform MIS!";
                    notifyError();
                }
            }
            catch{
                noti="Update Failed, Please Inform MIS!";
                notifyError();
            }
        }
    };

    async function postData(courseCode, courseTypeId) {
        const url = process.env.REACT_APP_API + `/updateCourseForCourseType`;
        const bodyData = { courseCode, courseTypeId };
        const configs = {
          headers:{
            'Authorization': 'Bearer '+localStorage.getItem("token"),
            'Content-Type': 'application/json',   
          }
        };
        try {
          const response = await axios.put(url, bodyData, configs);
          return response;
        } catch (error) {
          if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
            Cookies.remove('sessionData');
            localStorage.removeItem('token')
            navigate('/login')
            return;
          }
          console.error("Error:", error);
        }
    }

    /*
    async function postData(courseCode, courseTypeId) {
        const url = process.env.REACT_APP_API + `/updateCourseForCourseType`;
        const data = { courseCode, courseTypeId };
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                    
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }
    */
    
    return (
        <div>
            <ToastContainer />
            <div className='tableWrap'>
                <table>
                    <thead>
                        <tr>     
                            <th className="table-header" style={{width:'10%'}}>Course Code</th>
                            <th className="table-header" style={{width:'45%'}}>Course Name</th>
                            <th className="table-header">Course Type</th>
                            <th className="table-header">Awarding Body</th>
                            <th className="table-header">Study Mode</th>
                            <th className="table-header">Course Level</th>
                            <th className="table-header">Course Group</th>
                        </tr>
                    </thead>

                    { <tbody>
                        {data.map((element, index) => {
                            
                            let currCourseType = '5'; // course-type: others
                            if (element.course_type_id){
                                currCourseType = element.course_type_id;
                            }

                            return (
                                <tr key={index}>
                                    <td className="table-data1" id={element.course_code + '_code'} style={{textAlign: 'center'}}>{element.course_code}</td>
                                    <td className="table-data">{element.course_name}</td>        
                                    <td className="table-data">
                                        <CustomSelect type='noOthers' id={element.course_code + '_type'} value={currCourseType} data={courseTypeData} onChange={()=>{}} onUpdate={() => handleUpdate(element.course_code)}/>
                                    </td>      
                                    <td className="table-data">{element.awarding_body}</td>
                                    <td className="table-data">{element.study_mode}</td>
                                    <td className="table-data">{element.course_level}</td>
                                    <td className="table-data">{element.sort_main_group}</td>
                                </tr>
                            )
                        })} 
                    </tbody> }
                </table>     
            </div>
        </div>      
    )
}
export default ManageCourseTable;