import React from "react";
import Container from 'react-bootstrap/Container';
import { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/App.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import NavMenu from '../components/NavMenu';

function AccessDenied(){

    const navigate = useNavigate();
    const navigateRef = useRef(navigate);
    useEffect(() => {
      const savedSessionData = Cookies.get('sessionData');
      if (!savedSessionData) {
        navigateRef.current('/login');
      }
    }, []);
  

  return(
      <div>
          <NavMenu />
          <Container fluid>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90%"}}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ marginBottom: "1rem" }}>
                        <FontAwesomeIcon color={"rgba(40, 44, 52, 0.6)"} icon={faCircleXmark} style={{ height: '5rem' }} />
                    </div>
                    <div>
                        <h1 style={{ color: "rgba(40, 44, 52, 0.8)", textAlign: "center" }}>
                        You do not have permission to access this page!
                        </h1>
                    </div>
                </div>
            </div>
          </Container>
      </div>
  )
}

export default AccessDenied;