import '../../src/App.css';
import { React } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomInput from './CustomInput';
import { Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from "axios";


function ManageQuotaTable(props){

    const navigate = useNavigate();

    let noti = "";
    const notifySuccess = () => toast.success(noti);
    const notifyError = () => toast.error(noti);
    
    let data = props.tableData; 

    let q1_quota = 0;
    let q2_quota = 0;
    let q3_quota = 0;
    let q4_quota = 0;

    const handleUpdate = async () => {
        const dataBody = [];
        data.forEach(element => {
            dataBody.push({
                recruiterCode: element.recruiter_code,
                regionCode: element.region_code,
                quotaYear: props.year,
                janQuota: (document.getElementById(`${element.recruiter_code}_jan`)).value,
                febQuota: (document.getElementById(`${element.recruiter_code}_feb`)).value,
                marQuota: (document.getElementById(`${element.recruiter_code}_mar`)).value,
                aprQuota: (document.getElementById(`${element.recruiter_code}_apr`)).value,
                mayQuota: (document.getElementById(`${element.recruiter_code}_may`)).value,
                junQuota: (document.getElementById(`${element.recruiter_code}_jun`)).value,
                julQuota: (document.getElementById(`${element.recruiter_code}_jul`)).value,
                augQuota: (document.getElementById(`${element.recruiter_code}_aug`)).value,
                sepQuota: (document.getElementById(`${element.recruiter_code}_sep`)).value,
                octQuota: (document.getElementById(`${element.recruiter_code}_oct`)).value,
                novQuota: (document.getElementById(`${element.recruiter_code}_nov`)).value,
                decQuota: (document.getElementById(`${element.recruiter_code}_dec`)).value,
            });
        });
        try{
            const result = await postData(dataBody);
            if(result.data.success){
                noti = "Successfully Updated!";
                notifySuccess();
            }
            else{
                noti = "Update Failed, Please Inform MIS!";
                notifyError();
            }
        }
        catch{
            noti = "Update Failed, Please Inform MIS!";
            notifyError();
        }   
    };

    async function postData(bodyData) {
        const url = process.env.REACT_APP_API + `/createOrUpdateQuota`
        const configs = {
          headers:{
            'Authorization': 'Bearer '+localStorage.getItem("token"),
            'Content-Type': 'application/json',   
          }
        };
        try {
          const response = await axios.put(url,{recruitersQuota:bodyData}, configs);
          return response;
        } catch (error) {
          if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
            Cookies.remove('sessionData');
            localStorage.removeItem('token')
            navigate('/login')
            return;
          }
          console.error("Error:", error);
        }
    }

    /*
    async function postData(data) {
        const url = process.env.REACT_APP_API + `/createOrUpdateQuota`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({recruitersQuota:data}),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }
    */

    return (
        <div>
            <ToastContainer />
            <div className='tableWrap'>
                <table>
                    <thead>
                        <tr>     
                            <th className="table-header" style={{width:'12%'}}>Recruiter</th>
                            <th className="table-header" style={{width:'8%'}}>Territory</th>
                            <th className="table-header" style={{width:'5%'}}>Jan</th>
                            <th className="table-header" style={{width:'5%'}}>Feb</th>
                            <th className="table-header" style={{width:'5%'}}>Mar</th>
                            <th className="table-header" style={{width:'3%'}}>Q1</th>

                            <th className="table-header" style={{width:'5%'}}>Apr</th>
                            <th className="table-header" style={{width:'5%'}}>May</th>
                            <th className="table-header" style={{width:'5%'}}>Jun</th>
                            <th className="table-header" style={{width:'3%'}}>Q2</th>

                            <th className="table-header" style={{width:'5%'}}>Jul</th>
                            <th className="table-header" style={{width:'5%'}}>Aug</th>
                            <th className="table-header" style={{width:'5%'}}>Sep</th>
                            <th className="table-header" style={{width:'3%'}}>Q3</th>

                            <th className="table-header" style={{width:'5%'}}>Oct</th>
                            <th className="table-header" style={{width:'5%'}}>Nov</th>
                            <th className="table-header" style={{width:'5%'}}>Dec</th>
                            <th className="table-header" style={{width:'3%'}}>Q4</th>
                        </tr>
                    </thead>

                    { <tbody>
                        {data.map((element, index) => {
                            let inputState = "";
                            let currYear = new Date().getFullYear();
                            if (element.year && element.year < currYear){
                                inputState = "disabled";
                            }   
                            
                            q1_quota = (element.jan_quota + element.feb_quota + element.mar_quota);
                            q2_quota = (element.apr_quota + element.may_quota + element.jun_quota);
                            q3_quota = (element.jul_quota + element.aug_quota + element.sep_quota);
                            q4_quota = (element.oct_quota + element.nov_quota + element.dec_quota);                
                            
                            return (
                                <tr key={index}>
                                    <td className="table-data1" id={element.recruiter_code + '_name'}>{element.recruiter_name}</td>
                                    <td className="table-data">{element.country_name}</td>              

                                    <td className="table-data"><CustomInput id={element.recruiter_code + '_jan'} value={element.jan_quota} onChange = {() => {}} disabled={inputState}/></td>
                                    <td className="table-data"><CustomInput id={element.recruiter_code + '_feb'} value={element.feb_quota} onChange = {() => {}} disabled={inputState}/></td>
                                    <td className="table-data"><CustomInput id={element.recruiter_code + '_mar'} value={element.mar_quota} onChange = {() => {}} disabled={inputState}/></td>
                                    <td className="table-data3">{q1_quota}</td>

                                    <td className="table-data"><CustomInput id={element.recruiter_code + '_apr'} value={element.apr_quota} onChange = {() => {}} disabled={inputState}/></td>
                                    <td className="table-data"><CustomInput id={element.recruiter_code + '_may'} value={element.may_quota} onChange = {() => {}} disabled={inputState}/></td>
                                    <td className="table-data"><CustomInput id={element.recruiter_code + '_jun'} value={element.jun_quota} onChange = {() => {}} disabled={inputState}/></td>
                                    <td className="table-data3">{q2_quota}</td>

                                    <td className="table-data"><CustomInput id={element.recruiter_code + '_jul'} value={element.jul_quota} onChange = {() => {}} disabled={inputState}/></td>
                                    <td className="table-data"><CustomInput id={element.recruiter_code + '_aug'} value={element.aug_quota} onChange = {() => {}} disabled={inputState}/></td>
                                    <td className="table-data"><CustomInput id={element.recruiter_code + '_sep'} value={element.sep_quota} onChange = {() => {}} disabled={inputState}/></td>
                                    <td className="table-data3">{q3_quota}</td>

                                    <td className="table-data"><CustomInput id={element.recruiter_code + '_oct'} value={element.oct_quota} onChange = {() => {}} disabled={inputState}/></td>
                                    <td className="table-data"><CustomInput id={element.recruiter_code + '_nov'} value={element.nov_quota} onChange = {() => {}} disabled={inputState}/></td>
                                    <td className="table-data"><CustomInput id={element.recruiter_code + '_dec'} value={element.dec_quota} onChange = {() => {}} disabled={inputState}/></td>
                                    <td className="table-data3">{q4_quota}</td>
                                </tr>
                            )
                        })} 
                    </tbody> }
                </table> 
            </div>
            <br></br>
            <Button variant="primary" className="ms-auto" onClick={handleUpdate}>Update</Button>
        </div>

    )
}
export default ManageQuotaTable;