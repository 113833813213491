import React from "react";
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/App.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Card } from "react-bootstrap";
import axios from "axios";

import NavMenu from '../components/NavMenu';
import ManageAccessTable from "../components/ManageAccessTable";
import ManageIntervalTab from "../components/ManageIntervalTab";

function ManageAccess(){

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  useEffect(() => {
    const savedSessionData = Cookies.get('sessionData');
    if (savedSessionData) {
      const data = JSON.parse(savedSessionData);
      if (data['isAdmin'] !== 1) {
        navigateRef.current('/access_denied');
      }
    } else {
      navigateRef.current('/login');
    }
  }, []);


  const callbacksRef = useRef(() => callbacks());
  useEffect(() => {
    callbacksRef.current();
  }, []);
  

  const [data, setData] = useState([]);
  const [recruiterHeads, setRecruiterHeads] = useState([]);
  const [intervals, setIntervals] = useState([]);
  const [activeInterval, setActiveInterval] = useState();


  async function callbacks(){
    await getActiveInterval();
    await getIntervals();
    await getData();
    await getRecruitHeadsData();
  } 

  async function getData() {
    const url = process.env.REACT_APP_API + `/getAuthorizedUser`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setData(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getRecruitHeadsData() {
    const url = process.env.REACT_APP_API + `/getRecruiterHeads`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setRecruiterHeads(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getActiveInterval() {
    const url = process.env.REACT_APP_API + `/getActiveIntervalList`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      if (data){
        setActiveInterval(data[0]['id']);
      }
      
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }


  async function getIntervals() {
    const url = process.env.REACT_APP_API + `/getIntervalList`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      let intervalOptions = [];
      if(data){
        data.forEach((element, index)=>{
            intervalOptions.push({key: element.id, value: element.time});
        })
      } 
      setIntervals(intervalOptions);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }
  

  /*
  async function getData() {
    const url = process.env.REACT_APP_API + `/getAuthorizedUser`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }
    });
    const jsonData = await response.json();
    setData(jsonData['key']);
  }

  async function getRecruitHeadsData() {
    const url = process.env.REACT_APP_API + `/getRecruiterHeads`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }
    });
    const jsonData = await response.json();
    setRecruiterHeads(jsonData['key']);
  }

  async function getIntervals(){
    const url = process.env.REACT_APP_API + `/getIntervalList`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }
    });
    const jsonData = await response.json();
    setIntervals(jsonData['key']);
  }
  */

  return(
      <div>
          <NavMenu />
          <Container fluid>
            <Card className="dark">
              <Card.Body>
                <div className="d-flex flex-row">
                  <h5 style={{color:'white'}}>Manage Data Refresh-Rate</h5>
                </div>
                <ManageIntervalTab data={intervals} activeInterval = {activeInterval}/>
              </Card.Body>
            </Card>
            <br></br>

            <Card className="dark">
              <Card.Body>
                <div className="d-flex flex-row">
                  <h5 style={{color:'white'}}>Manage Access Levels</h5>
                </div>
                <ManageAccessTable tableData = {data} recruitHeads = {recruiterHeads}/>
              </Card.Body>
            </Card>
          </Container>
      </div>
  )
}

export default ManageAccess;