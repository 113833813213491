import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/App.css';
import { Link } from 'react-router-dom'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable, faChartLine, faArrowRightFromBracket, faUserPen } from '@fortawesome/free-solid-svg-icons';


function NavMenu() {
  const logo = require('../img/eaim_logo.png');

  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary"  bg="dark" data-bs-theme="dark">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src= {logo}
              width="80"
              alt=""
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">

              <Nav.Item style={{display: 'inline-flex', paddingRight: '15px'}}>
                <FontAwesomeIcon icon={faChartLine} style={{margin: 'auto'}}/>
                <Nav.Link as={Link} to="/"> 
                  Dashboard
                </Nav.Link>
              </Nav.Item>
              
              <Nav.Item style={{display: 'inline-flex', paddingRight: '15px'}}>
                <FontAwesomeIcon icon={faTable} style={{margin: 'auto'}}/>
                <NavDropdown title="Reports" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/recruiter_enrollment_performance_yearly">Recruiters' Enrolment Performance</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/recruiter_application_performance_yearly">Recruiters' Application Performance</NavDropdown.Item>
                  <NavDropdown.Divider />

                  <NavDropdown.Item as={Link} to="/agent_performance_report">Agent Performance Report</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/agent_performance_comparative_analysis_report">Agent Performance Comparative Analysis Report</NavDropdown.Item>                
                  <NavDropdown.Divider />

                  <NavDropdown.Item as={Link} to="/course_enrollment_report">Course Enrolment Report</NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>

              <Nav.Item style={{display: 'inline-flex', paddingRight: '15px'}}>
                <FontAwesomeIcon icon={faUserPen} style={{margin: 'auto'}}/>
                <NavDropdown title="Management" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/manage_quota">Manage Quota</NavDropdown.Item>
                  {/* <NavDropdown.Item href="#recruiterQuota">Recruiter Quota By Program & Acad.Level</NavDropdown.Item> */}
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/manage_course">Manage Courses</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/manage_recruiter">Manage Recruiters</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/manage_access">Manage Access Level</NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>

            </Nav>
          </Navbar.Collapse>        
        </Container>
        
        <Link as={Link} to="/logout" className="logout-link"> <FontAwesomeIcon icon={faArrowRightFromBracket} style={{margin: 'auto'}}/> Logout</Link>
      </Navbar>
    </div>
    
  );
}

export default NavMenu;