import React from 'react';
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/App.css';
import NavMenu from '../components/NavMenu';
import RecruiterPerformanceYearlyTable from '../components/RecruiterPerformanceYearlyTable';


function RecruiterEnrollmentPerformanceYearly() {

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  let checkRegionalDirector;                    // to use in callbacks onload
  let uId;

  useEffect(() => {
    const savedSessionData = Cookies.get('sessionData');
    if (savedSessionData) {
      const data = JSON.parse(savedSessionData);
      if (data['time']) {
        setIntervalValue(data['time']);
      }
      if (data['isViewAllowed'] !== 1) {
        navigateRef.current('/access_denied');
      }
      if (data['isRegionalDirector']) {
        setIsRegionalDirector(data['isRegionalDirector']);
        setUserId(data['userId']);
        checkRegionalDirector = data['isRegionalDirector'];
        uId = data['userId'];
      }
    } else {
      navigateRef.current('/login');
    }
  }, []);


  const callbacksRef = useRef(() => callbacks());
  useEffect(() => {
    callbacksRef.current();
  }, []);


  const [interval, setIntervalValue] = useState([300000]);    // to refresh and reload (initial 5 min)
  useEffect(() => {
    const intervalId = setInterval(async () => {
        callbacksRef.current();
    }, interval);
    return () => {
        clearInterval(intervalId);                           // Clear the interval when the component unmounts
    };
  }, [interval]);   


  const [isRegionalDirector, setIsRegionalDirector] = useState([]);
  const [userId, setUserId] = useState([]);

  let currYear = new Date().getFullYear();
  let year1 = currYear-1;
  let year2 = currYear-2;

  const [region, setRegionInfo] = useState(["All Regions"]);
  const [course, setCourseInfo] = useState(["All Courses"]);

  const [data, setData] = useState([]);
  const [courseTypes, setCourseTypes] = useState([]);
  const [selectedYear, setYear] = useState([]);
  const [selectedRegion, setRegion] = useState([]);
  const [selectedCourseType, setCourseType] = useState([]);
  const [recruitHeads, setRecruitHeads] = useState([]);


  async function callbacks(){
    setYear(currYear);
    setRegion("");
    await getData(currYear,"","",uId, checkRegionalDirector);
    await getCourseTypeData();
    await getRecruitHeadsData();
    console.log("Refreshed");
  }

  
  const handleYearSelect = async (eventKey) => {
    setYear(eventKey);
    await getData(eventKey, selectedRegion, selectedCourseType, userId, isRegionalDirector);
  };


  const handleRegionSelect = async (eventKey) => {
    setRegion(eventKey);
    if (eventKey === "0011"){
      setRegionInfo("China");
    }
    else if(eventKey === "0010"){
      setRegionInfo("SG/ ASEAN")
    }
    else{
      setRegionInfo("All Regions");
    }
    await getData(selectedYear, eventKey, selectedCourseType, userId, isRegionalDirector);
  }


  const handleCourseTypeSelect = async (eventKey) => {
    setCourseType(eventKey);
    let key = parseInt(eventKey);
    if(courseTypes){
      let temp = "All Courses";
      courseTypes.forEach((element, index) => {
        if (key === element['key']){
          temp = element['value'];
        }
      });
      setCourseInfo(temp);
    }
    await getData(selectedYear, selectedRegion, eventKey, userId, isRegionalDirector);
  }


  async function getData(selectedYear, selectedRegion, selectedCourseType, userId, isRegionalDirector) {
    const url = process.env.REACT_APP_API + `/getNewPerformance`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {
        year: selectedYear,
        type: 'enrollment',
        recTeam: selectedRegion,
        courseTypeId: selectedCourseType,
        isRecruitmentDirector: isRegionalDirector,
        userId: userId
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setData(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }


  async function getCourseTypeData(){
    const url = process.env.REACT_APP_API + `/getCourseTypeByList`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setCourseTypes(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }


  async function getRecruitHeadsData() {
    const url = process.env.REACT_APP_API + `/getRecruiterHeads`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setRecruitHeads(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    } 
  }

  /*
  async function getData(selectedYear, selectedRegion, selectedCourseType) {
    const params = new URLSearchParams({
      year: selectedYear,
      type: 'enrollment',
      recTeam: selectedRegion,
      courseTypeId: selectedCourseType
    });
    const url = process.env.REACT_APP_API + `/getNewPerformance?${params}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }
    });
    const jsonData = await response.json();
    setData(jsonData['key']);
  }

  async function getCourseTypeData() {
    const url = process.env.REACT_APP_API + `/getCourseTypeByList`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }
    });
    const jsonData = await response.json();
    setCourseTypes(jsonData['key']);
  }

  async function getRecruitHeadsData() {
    const url = process.env.REACT_APP_API + `/getRecruiterHeads`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }
    });
    const jsonData = await response.json();
    setRecruitHeads(jsonData['key']);
  }
*/ 

  return (
    <div>
        <NavMenu />
        <Container fluid>

          <div className="d-flex flex-row">
            <DropdownButton variant="secondary" id="selectCategory" title="Category" style={{marginRight:'5px'}}>
              <Dropdown.Item as={Link} to={"/recruiter_enrollment_performance_yearly"}>Yearly</Dropdown.Item>
              <Dropdown.Item as={Link} to={"/recruiter_enrollment_performance_monthly"}>Monthly</Dropdown.Item>
              <Dropdown.Item as={Link} to={"/recruiter_enrollment_performance_qtrly"}>Quarterly</Dropdown.Item>
            </DropdownButton>
                
            <DropdownButton variant="secondary" id="yearSelect" title="Year" style={{marginRight:'5px'}} onSelect={handleYearSelect}>
              <Dropdown.Item eventKey={currYear}>{currYear}</Dropdown.Item>
              <Dropdown.Item eventKey={year1}>{year1}</Dropdown.Item>
              <Dropdown.Item eventKey={year2}>{year2}</Dropdown.Item>
            </DropdownButton>

            <DropdownButton variant="secondary" id="regionSelect" title="Region" style={{marginRight:'5px'}} onSelect={handleRegionSelect}>
              <Dropdown.Item eventKey={''}>All</Dropdown.Item>
              <Dropdown.Item eventKey={'0011'}>China</Dropdown.Item>
              <Dropdown.Item eventKey={'0010'}>SG/ASEAN</Dropdown.Item>
            </DropdownButton>

            <DropdownButton variant="secondary" id="courseSelect" title="Course Type" style={{marginRight:'5px'}} onSelect={handleCourseTypeSelect}>
              <Dropdown.Item eventKey={''}>All</Dropdown.Item>
                {courseTypes.map((element, index) => {
                  return (
                    <Dropdown.Item key={index} eventKey={element.key}>{element.value}</Dropdown.Item>
                  )
                })} 
            </DropdownButton>

            <span className="badge text-bg-dark" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', opacity:'0.7' }} >
              <p style={{margin:"auto"}}>Enrolment Performance Yearly - {selectedYear} - {region} - {course}</p>
            </span>
          </div>

          <RecruiterPerformanceYearlyTable tableData = {data} year = {selectedYear} recruitHeads = {recruitHeads}/>
        </Container>
    </div>
  );
}

export default RecruiterEnrollmentPerformanceYearly;