import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import AccessDenied from "./pages/AccessDenied";

import Dashboard from "./pages/DashboardHome";
import RecruiterEnrollmentPerformanceYearly from "./pages/RecruiterEnrollmentPerformanceYearly";
import RecruiterEnrollmentPerformanceMonthly from "./pages/RecruiterEnrollmentPerformanceMonthly";
import RecruiterEnrollmentPerformanceQtrly from "./pages/RecruiterEnrollmentPerformanceQtrly";
import RecruiterApplicationPerformanceYearly from "./pages/RecruiterApplicationPerformanceYearly";
import RecruiterApplicationPerformanceMonthly from "./pages/RecruiterApplicationPerformanceMonthly";
import RecruiterApplicationPerformanceQtrly from "./pages/RecruiterApplicationPerformanceQtrly";
import AgentPerformanceReport from "./pages/AgentPerformanceReport";
import AgentPerformanceComparativeAnalysisReport from "./pages/AgentPerformanceComparativeAnalysisReport";
import CourseEnrollmentReport from "./pages/CourseEnrollmentReport";

import ManageQuota from "./pages/ManageQuota";
import ManageCourse from "./pages/ManageCourse";
import ManageRecruiter from "./pages/ManageRecruiter";
import ManageAccess from "./pages/ManageAccess";

function App() {

  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />}/>
        <Route path="/logout" element={<LogoutPage />}/>
        <Route path="/" element={<Dashboard />} />
        <Route path="/access_denied" element={<AccessDenied />} />
        
        <Route path="/recruiter_enrollment_performance_yearly" element={<RecruiterEnrollmentPerformanceYearly />}/>
        <Route path="/recruiter_enrollment_performance_monthly" element={<RecruiterEnrollmentPerformanceMonthly />}/>
        <Route path="/recruiter_enrollment_performance_qtrly" element={<RecruiterEnrollmentPerformanceQtrly />}/>
        <Route path="/recruiter_application_performance_yearly" element={<RecruiterApplicationPerformanceYearly />}/>
        <Route path="/recruiter_application_performance_monthly" element={<RecruiterApplicationPerformanceMonthly />}/>
        <Route path="/recruiter_application_performance_qtrly" element={<RecruiterApplicationPerformanceQtrly />}/>
      
        <Route path="/agent_performance_report" element={<AgentPerformanceReport />}/>
        <Route path="/agent_performance_comparative_analysis_report" element={<AgentPerformanceComparativeAnalysisReport />}/>
        <Route path="/course_enrollment_report" element={<CourseEnrollmentReport />}/>

        <Route path="/manage_quota" element={<ManageQuota />}/>
        <Route path="/manage_course" element={<ManageCourse />}/>
        <Route path="/manage_recruiter" element={<ManageRecruiter />}/>
        <Route path="/manage_access" element={<ManageAccess />}/>
      </Routes>
    </>
  );
}


export default App;