import '../../src/App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function RecruiterPerformanceQtrlyTable(props){

    let data = props.tableData; 
    let data1 = props.recruitHeads;
    let recruitHeads = [];
    let recruiters = [];

    data1.forEach((element, index) => {
        if (element.recruiter_code != null && element.recruiter_code !== '0013'){
            recruitHeads.push(element.recruiter_code);
        }
    });

    //Sorting recruiters list
    recruitHeads.forEach((recruitHead, i)=>{
        data.forEach((recruiter, index)=>{
            if (recruitHead === (recruiter.recruiter_code) && recruiter.is_team_head === 1){    // recruitment head first
                recruiters.push(recruiter);
            }
        })
        data.forEach((recruiter, index)=>{                                                      // then recruiters under respective recruit head
            if(recruitHead === (recruiter.supervisorCode)){
                recruiters.push(recruiter);
            }
        })
    });
    data.forEach((recruiter, index)=>{                                                          // then recruiters without recruit head
        if(!recruiter.supervisorCode){
            recruiters.push(recruiter);
        }
    })

    let groupOrder = recruitHeads[0];

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    let curYear = new Date().getFullYear();
    let curMonthIndex = new Date().getMonth();
    let curMonth = months[curMonthIndex];

    let qtr = 1;
    if (curMonthIndex >= 4 && curMonthIndex < 7){
        qtr = 2;
    }
    else if (curMonthIndex >= 7 && curMonthIndex < 10){
        qtr = 3;
    }
    else if (curMonthIndex >= 10){
        qtr = 4;
    }
    else if (curYear != props.year){
        qtr = 4;
        curMonthIndex = 11;
        curMonth = months[curMonthIndex];
    }

    let month_count = 0;
    let month_quota = 0;
    let month_vs = 0;
    let qtr_count = 0;
    let qtr_quota = 0;
    let qtr_vs = 0;

    let sub_total_month_count = 0;
    let sub_total_month_quota = 0;
    let sub_total_month_vs = 0;

    let sub_total_qtr_count = 0;
    let sub_total_qtr_quota = 0;
    let sub_total_qtr_vs = 0;

    let sub_total_count = 0;
    let sub_total_quota = 0;
    let sub_total_vs = 0;

    let total_month_count = 0;
    let total_month_quota = 0;
    let total_month_vs = 0;
    let total_qtr_count = 0;
    let total_qtr_quota = 0;
    let total_qtr_vs = 0;

    let grand_total_count = 0;
    let grand_total_quota = 0;
    let grand_total_vs = 0;

    function resetSubData (){
        sub_total_month_count = 0;
        sub_total_month_quota = 0;
        sub_total_month_vs = 0;
    
        sub_total_qtr_count = 0;
        sub_total_qtr_quota = 0;
        sub_total_qtr_vs = 0;
    
        sub_total_count = 0;
        sub_total_quota = 0;
        sub_total_vs = 0;
    }

    return (
        <div className='tableWrap'>
            <table>
                <thead>
                    <tr>
                        <th className="table-header" rowSpan="3" colSpan="1" style={{width:'8%'}}>Recruiter</th>
                        <th className="table-header" rowSpan="3" colSpan="1" style={{width:'5%'}}>Territory</th>               
                        <th className="table-header" rowSpan="1" colSpan="3">Current-Month {curMonth} {props.year}</th>
                        <th className="table-header"rowSpan="1" colSpan="3">Qtr-To-Date Q{qtr} {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Year-To-Date {props.year}</th>
                    </tr>
                    <tr>
                        {Array.from({ length: 3 }, (_, index) => (
                            <React.Fragment key={index}>
                                <th className="table-header">Actual</th>
                                <th className="table-header">Plan</th>
                                <th className="table-header">Actual vs Plan</th>
                            </React.Fragment>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {recruiters.map((element, index) => {
                        const nextElement = recruiters[index + 1];

                        if (nextElement) {
                            if (nextElement.is_team_head && recruitHeads.includes(nextElement.recruiter_code)){
                                groupOrder = nextElement.recruiter_code;
                            }
                            else{
                                groupOrder = nextElement.supervisorCode;
                            }   
                        }

                        const isLastElement = index === recruiters.length - 1;

                        const values = Object.values(element);

                        const q1_count = element.jan_count + element.feb_count + element.mar_count;
                        const q2_count = element.apr_count + element.may_count + element.jun_count;
                        const q3_count = element.jul_count + element.aug_count + element.sep_count; 
                        const q4_count = element.oct_count + element.nov_count + element.dec_count;  

                        const q1_quota = element.jan_quota + element.feb_quota + element.mar_quota;
                        const q2_quota = element.apr_quota + element.may_quota + element.jun_quota;
                        const q3_quota = element.jul_quota + element.aug_quota + element.sep_quota; 
                        const q4_quota = element.oct_quota + element.nov_quota + element.dec_quota;

                        const q1_vs = ((q1_count === 0 || q1_quota === 0) ? 0 : Math.round((q1_count/q1_quota)*100));
                        const q2_vs = ((q2_count === 0 || q2_quota === 0) ? 0 : Math.round((q2_count/q2_quota)*100));
                        const q3_vs = ((q3_count === 0 || q3_quota === 0) ? 0 : Math.round((q3_count/q3_quota)*100));
                        const q4_vs = ((q4_count === 0 || q4_quota === 0) ? 0 : Math.round((q4_count/q4_quota)*100));

                        if (qtr === 1){
                            qtr_count = q1_count; qtr_quota = q1_quota; qtr_vs = q1_vs;
                        }
                        else if (qtr === 2){
                            qtr_count = q2_count; qtr_quota = q2_quota; qtr_vs = q2_vs;
                        }
                        else if (qtr === 3){
                            qtr_count = q3_count; qtr_quota = q3_quota; qtr_vs = q3_vs;
                        }
                        else{
                            qtr_count = q4_count; qtr_quota = q4_quota; qtr_vs = q4_vs;
                        }

                        let currMonth = months[curMonthIndex];
                        month_count = element[`${currMonth.toLowerCase()}_count`];
                        month_quota = element[`${currMonth.toLowerCase()}_quota`];
                        month_vs = ((month_count === 0 || month_quota === 0) ? 0 : Math.round((month_count/month_quota)*100));

                        total_month_count += month_count;
                        total_month_quota += month_quota;
                        total_month_vs = ((total_month_count === 0 || total_month_quota === 0) ? 0 : Math.round((total_month_count/total_month_quota)*100));
                        
                        total_qtr_count += qtr_count;
                        total_qtr_quota += qtr_quota;
                        total_qtr_vs = ((total_qtr_count === 0 || total_qtr_quota === 0) ? 0 : Math.round((total_qtr_count/total_qtr_quota)*100));

                        const total_count = q1_count + q2_count + q3_count + q4_count;
                        const total_quota = q1_quota + q2_quota + q3_quota + q4_quota;
                        const total_vs =  ((total_count === 0 || total_quota === 0) ? 0 : Math.round((total_count/total_quota)*100));


                        sub_total_month_count += month_count;
                        sub_total_month_quota += month_quota;
                        sub_total_month_vs = ((sub_total_month_count === 0 || sub_total_month_quota === 0) ? 0 : Math.round((sub_total_month_count/sub_total_month_quota)*100));
                    
                        sub_total_qtr_count += qtr_count;
                        sub_total_qtr_quota += qtr_quota;
                        sub_total_qtr_vs = ((sub_total_qtr_count === 0 || sub_total_qtr_quota === 0) ? 0 : Math.round((sub_total_qtr_count/sub_total_qtr_quota)*100));
                        
                        sub_total_count += total_count;
                        sub_total_quota += total_quota;
                        sub_total_vs = ((sub_total_count === 0 || sub_total_quota === 0) ? 0 : Math.round((sub_total_count/sub_total_quota)*100));


                        grand_total_count += total_count;
                        grand_total_quota += total_quota;
                        grand_total_vs = ((grand_total_count === 0 || grand_total_quota === 0) ? 0 : Math.round((grand_total_count/grand_total_quota)*100));

                        // to add a sub total row when recruitment team is changed
                        if ((groupOrder !== element.supervisorCode && groupOrder !== element.recruiter_code) || isLastElement){

                            return (
                                <>      
                                    <tr key={index}>
                                        <td className="table-data">{element.recruiter_name}</td>
                                        <td className="table-data">{element.country_name}</td>
        
                                        <td className="table-data">{month_count}</td>
                                        <td className="table-data">{month_quota}</td>
                                        <td className="table-data">{month_vs}%</td>
        
                                        <td className="table-data">{qtr_count}</td>
                                        <td className="table-data">{qtr_quota}</td>
                                        <td className="table-data">{qtr_vs}%</td>
        
                                        <td className="table-data">{total_count}</td>
                                        <td className="table-data">{total_quota}</td>
                                        <td className="table-data">{total_vs}%</td>
                                    </tr>
                                    <tr className="sub-group">
                                        <td className="table-data4" colSpan="2">Sub-Total</td>                             
        
                                        <td className="table-data4">{sub_total_month_count}</td>
                                        <td className="table-data4">{sub_total_month_quota}</td>
                                        <td className="table-data4">{sub_total_month_vs}%</td>
        
                                        <td className="table-data4">{sub_total_qtr_count}</td>
                                        <td className="table-data4">{sub_total_qtr_quota}</td>
                                        <td className="table-data4">{sub_total_qtr_vs}%</td>
        
                                        <td className="table-data4">{sub_total_count}</td>
                                        <td className="table-data4">{sub_total_quota}</td>
                                        <td className="table-data4">{sub_total_vs}%</td>
                                    </tr>
                                    {resetSubData()}
                                </>    
                            )
                        }

                        else {
                            return (
                                <tr key={index}>
                                    <td className="table-data">{element.recruiter_name}</td>
                                    <td className="table-data">{element.country_name}</td>
    
                                    <td className="table-data">{month_count}</td>
                                    <td className="table-data">{month_quota}</td>
                                    <td className="table-data">{month_vs}%</td>
    
                                    <td className="table-data">{qtr_count}</td>
                                    <td className="table-data">{qtr_quota}</td>
                                    <td className="table-data">{qtr_vs}%</td>
    
                                    <td className="table-data">{total_count}</td>
                                    <td className="table-data">{total_quota}</td>
                                    <td className="table-data">{total_vs}%</td>
                                </tr>
                            )
                        }

                    })}
                    <tr>
                        <td className="table-data2">Grand Total</td>
                        <td className="table-data2">All Region</td>
                        <td className="table-data2">{total_month_count}</td>
                        <td className="table-data2">{total_month_quota}</td>
                        <td className="table-data2">{total_month_vs}%</td>
                        <td className="table-data2">{total_qtr_count}</td>
                        <td className="table-data2">{total_qtr_quota}</td>
                        <td className="table-data2">{total_qtr_vs}%</td>
                        <td className="table-data2">{grand_total_count}</td>
                        <td className="table-data2">{grand_total_quota}</td>
                        <td className="table-data2">{grand_total_vs}%</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default RecruiterPerformanceQtrlyTable;