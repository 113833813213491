import '../../src/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {React} from 'react';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserTie, faUserSecret, faCalendar, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

function InfoCard(props){
    let studentEnrollCount = props.stuEnroll;
    let studentApplyCount = props.stuApply;
    let agentCount = props.agents;
    let recruiterCount = props.recruiters;


    return (
        <div>
            <Card className="dark" style={{width: '90%', minHeight:'42rem', marginTop: '10px'}}>
                <Card.Body>
                    <div className="d-flex flex-wrap" style={{paddingTop:'10px'}}> 
                        <div className="col-3" style={{margin:'auto', textAlign:'left'}}>
                            <FontAwesomeIcon color={"white"} icon={faCalendarAlt} style={{height:'4rem'}}/>
                        </div>
                        <div className="col">
                            <h1 className={'info-3'}>{props.currYear}</h1>
                            <h1 className={"info-4"}>Calendar Year</h1>
                        </div> 
                    </div>
                    <div className="divider"></div>
                    <div className="d-flex flex-wrap"> 
                        <div className="col-3" style={{margin:'auto', textAlign:'left'}}>
                            <FontAwesomeIcon color={"white"} icon={faUser} style={{height:'4rem'}}/>
                        </div>
                        <div className="col">
                            <h1 className={'info-3'}>{studentEnrollCount}</h1>
                            <h1 className={"info-4"}>Student Enrolments</h1>
                        </div> 
                    </div>
                    <div className="divider"></div>
                    <div className="d-flex flex-wrap"> 
                        <div className="col-3" style={{margin:'auto', textAlign:'left'}}>
                            <FontAwesomeIcon color={"white"} icon={faUser} style={{height:'4rem'}}/>
                        </div>
                        <div className="col p-0">
                            <h1 className={'info-3'}>{studentApplyCount}</h1>
                            <h1 className={"info-4"}>Student Applications</h1>
                        </div> 
                    </div>
                    <div className="divider"></div>
                    {/* <div className="d-flex flex-wrap" style={{display: 'flex', alignItems: 'center'}}> 
                        <div className="col-3" style={{margin:'auto', textAlign:'left'}}>
                            <FontAwesomeIcon color={"white"} icon={faUser} style={{height:'4rem'}}/>
                        </div>
                        <div className="col" style={{paddingLeft:'5px'}}>
                            <div className="row">
                                <h1 style={{paddingBottom:'1rem', color:'white', fontSize:'17px', paddingLeft:'5px'}}>Enrolled</h1>
                            </div>
                            <div className="row d-flex" style={{ paddingLeft:'5px'}} >
                                <div className="col p-0">
                                    <h1 className={'info-4 p-0'}  style={{fontSize:'20px'}}>{acadCount}</h1>
                                    <h1 className={"info-4 p-0"}>Acad</h1>
                                </div> 
                                <div className="col p-0">
                                    <h1 className={'info-4 p-0'} style={{fontSize:'20px'}}>{prepCount}</h1>
                                    <h1 className={"info-4 p-0"}>Prep</h1>
                                </div>  
                                <div className="col p-0">
                                    <h1 className={'info-4 p-0'} style={{fontSize:'20px'}}>{intCount}</h1>
                                    <h1 className={"info-4 p-0"}>Int'l</h1>
                                </div> 
                                <div className="col p-0">
                                    <h1 className={'info-4 p-0'} style={{fontSize:'20px'}}>{aLvlCount}</h1>
                                    <h1 className={"info-4 p-0"}>A-Lvl</h1>
                                </div> 
                                <div className="col p-0">
                                    <h1 className={'info-4 p-0'} style={{fontSize:'20px'}}>{othersCount}</h1>
                                    <h1 className={"info-4 p-0"}>Others</h1>
                                </div>
                            </div>   
                        </div>
 
                    </div>
                    <div className="divider"></div> */}

                    <div className="d-flex flex-wrap"> 
                        <div className="col-3" style={{margin:'auto', textAlign:'left'}}>
                            <FontAwesomeIcon color={"white"} icon={faUserSecret} style={{height:'4rem'}}/>
                        </div>
                        <div className="col">
                            <h1 className={'info-3'}>{agentCount}</h1>
                            <h1 className={"info-4"}>Activated Agents</h1>
                        </div> 
                    </div>
                    <div className="divider"></div>
                    <div className="d-flex flex-wrap"> 
                        <div className="col-3" style={{margin:'auto', textAlign:'left'}}>
                            <FontAwesomeIcon color={"white"} icon={faUserTie} style={{height:'4rem'}}/>
                        </div>
                        <div className="col">
                            <h1 className={'info-3'}>{recruiterCount}</h1>
                            <h1 className={"info-4"}>Recruiters</h1>
                        </div> 
                    </div>
                </Card.Body> 
            </Card>
        </div>    
    )
}
export default InfoCard;