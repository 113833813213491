import '../../src/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function RecruiterPerformanceYearlyTable(props){

    let data = props.tableData; 
    let data1 = props.recruitHeads;
    let recruitHeads = [];
    let recruiters = [];

    data1.forEach((element, index) => {
        if (element.recruiter_code != null && element.recruiter_code !== '0013'){
            recruitHeads.push(element.recruiter_code);
        }
    });

    //Sorting recruiters list
    recruitHeads.forEach((recruitHead, i)=>{
        data.forEach((recruiter, index)=>{
            if (recruitHead === (recruiter.recruiter_code) && recruiter.is_team_head === 1){    // recruitment head first
                recruiters.push(recruiter);
            }
        })
        data.forEach((recruiter, index)=>{                                                      // then recruiters under respective recruit head
            if(recruitHead === (recruiter.supervisorCode)){
                recruiters.push(recruiter);
            }
        })
    });
    data.forEach((recruiter, index)=>{                                                          // then recruiters without recruit head
        if(!recruiter.supervisorCode){
            recruiters.push(recruiter);
        }
    })

    let groupOrder = recruitHeads[0];

    let sub_q1_quota = 0;
    let sub_q1_count = 0;
    let sub_q1_vs = 0;

    let sub_q2_quota = 0;
    let sub_q2_count = 0;
    let sub_q2_vs = 0;

    let sub_q3_quota = 0;
    let sub_q3_count = 0;
    let sub_q3_vs = 0;

    let sub_q4_quota = 0;
    let sub_q4_count = 0;
    let sub_q4_vs = 0;

    let sub_total_quota = 0;
    let sub_total_count = 0;
    let sub_total_vs = 0;

    let grand_total_count_q1 = 0;
    let grand_total_count_q2 = 0;
    let grand_total_count_q3 = 0;
    let grand_total_count_q4 = 0;
    let grand_total_quota_q1 = 0; 
    let grand_total_quota_q2 = 0; 
    let grand_total_quota_q3 = 0;
    let grand_total_quota_q4 = 0;
    let grand_total_vs_q1 = 0;
    let grand_total_vs_q2 = 0;
    let grand_total_vs_q3 = 0;
    let grand_total_vs_q4 = 0;
    let grand_total_count = 0;
    let grand_total_quota = 0;
    let grand_total_vs = 0;

    function resetSubData (){
        sub_q1_quota = 0;
        sub_q1_count = 0;
        sub_q1_vs = 0;
    
        sub_q2_quota = 0;
        sub_q2_count = 0;
        sub_q2_vs = 0;
    
        sub_q3_quota = 0;
        sub_q3_count = 0;
        sub_q3_vs = 0;
    
        sub_q4_quota = 0;
        sub_q4_count = 0;
        sub_q4_vs = 0;
    
        sub_total_quota = 0;
        sub_total_count = 0;
        sub_total_vs = 0;
    }

    return (
        <div className='tableWrap'>
            <table>
                <thead>
                    <tr>
                        <th className="table-header" rowSpan="3" colSpan="1" style={{width:'8%'}}>Recruiter</th>
                        <th className="table-header" rowSpan="3" colSpan="1" style={{width:'5%'}}>Territory</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Q1 {props.year}</th>
                        <th className="table-header"rowSpan="1" colSpan="3">Q2 {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Q3 {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Q4 {props.year}</th>
                        <th className="table-header" rowSpan="1" colSpan="3">Total {props.year}</th>
                    </tr>
                    <tr>
                        <th className="table-header">Actual</th>
                        <th className="table-header">Plan</th>
                        <th className="table-header">Plan vs Actual</th>

                        <th className="table-header">Actual</th>
                        <th className="table-header">Plan</th>
                        <th className="table-header">Plan vs Actual</th>

                        <th className="table-header">Actual</th>
                        <th className="table-header">Plan</th>
                        <th className="table-header">Plan vs Actual</th>

                        <th className="table-header">Actual</th>
                        <th className="table-header">Plan</th>
                        <th className="table-header">Plan vs Actual</th>

                        <th className="table-header">Actual</th>
                        <th className="table-header">Plan</th>
                        <th className="table-header">Plan vs Actual</th>
                    </tr>
                </thead>

                <tbody>
                    {recruiters.map((element, index) => {
                        const nextElement = recruiters[index + 1];
                        
                        if (nextElement) {
                            if (nextElement.is_team_head && recruitHeads.includes(nextElement.recruiter_code)){
                                groupOrder = nextElement.recruiter_code;
                            }
                            else{
                                groupOrder = nextElement.supervisorCode;
                            }   
                        }

                        const isLastElement = index === recruiters.length - 1;

                        const q1_count = element.jan_count + element.feb_count + element.mar_count;
                        const q2_count = element.apr_count + element.may_count + element.jun_count;
                        const q3_count = element.jul_count + element.aug_count + element.sep_count; 
                        const q4_count = element.oct_count + element.nov_count + element.dec_count;  
    
                        const q1_quota = element.jan_quota + element.feb_quota + element.mar_quota;
                        const q2_quota = element.apr_quota + element.may_quota + element.jun_quota;
                        const q3_quota = element.jul_quota + element.aug_quota + element.sep_quota; 
                        const q4_quota = element.oct_quota + element.nov_quota + element.dec_quota;
    
                        const q1_vs = ((q1_count === 0 || q1_quota === 0) ? 0 : Math.round((q1_count/q1_quota)*100));
                        const q2_vs = ((q2_count === 0 || q2_quota === 0) ? 0 : Math.round((q2_count/q2_quota)*100));
                        const q3_vs = ((q3_count === 0 || q3_quota === 0) ? 0 : Math.round((q3_count/q3_quota)*100));
                        const q4_vs = ((q4_count === 0 || q4_quota === 0) ? 0 : Math.round((q4_count/q4_quota)*100));
    
                        const total_count = q1_count + q2_count + q3_count + q4_count;
                        const total_quota = q1_quota + q2_quota + q3_quota + q4_quota;
                        const total_vs =  ((total_count === 0 || total_quota === 0) ? 0 : Math.round((total_count/total_quota)*100));

                        sub_q1_quota += q1_quota;
                        sub_q1_count += q1_count;
                        sub_q1_vs = ((sub_q1_count === 0 || sub_q1_quota === 0) ? 0 : Math.round((sub_q1_count/sub_q1_quota)*100));

                        sub_q2_quota += q2_quota;
                        sub_q2_count += q2_count;
                        sub_q2_vs = ((sub_q2_count === 0 || sub_q2_quota === 0) ? 0 : Math.round((sub_q2_count/sub_q2_quota)*100));

                        sub_q3_quota += q3_quota;
                        sub_q3_count += q3_count;
                        sub_q3_vs = ((sub_q3_count === 0 || sub_q3_quota === 0) ? 0 : Math.round((sub_q3_count/sub_q3_quota)*100));

                        sub_q4_quota += q4_quota;
                        sub_q4_count += q4_count;
                        sub_q4_vs = ((sub_q4_count === 0 || sub_q4_quota === 0) ? 0 : Math.round((sub_q4_count/sub_q4_quota)*100));
    
                        sub_total_quota = sub_q1_quota + sub_q2_quota + sub_q3_quota + sub_q4_quota;
                        sub_total_count = sub_q1_count + sub_q2_count + sub_q3_count + sub_q4_count;
                        sub_total_vs = ((sub_total_count === 0 || sub_total_quota === 0) ? 0 : Math.round((sub_total_count/sub_total_quota)*100));

                        grand_total_count_q1 += q1_count;
                        grand_total_count_q2 += q2_count;
                        grand_total_count_q3 += q3_count;
                        grand_total_count_q4 += q4_count;
    
                        grand_total_quota_q1 += q1_quota;
                        grand_total_quota_q2 += q2_quota;
                        grand_total_quota_q3 += q3_quota;
                        grand_total_quota_q4 += q4_quota;
    
                        grand_total_vs_q1 = ((grand_total_count_q1 === 0 || grand_total_quota_q1 === 0) ? 0 : Math.round((grand_total_count_q1/grand_total_quota_q1)*100));
                        grand_total_vs_q2 = ((grand_total_count_q2 === 0 || grand_total_quota_q2 === 0) ? 0 : Math.round((grand_total_count_q2/grand_total_quota_q2)*100));
                        grand_total_vs_q3 = ((grand_total_count_q3 === 0 || grand_total_quota_q3 === 0) ? 0 : Math.round((grand_total_count_q3/grand_total_quota_q3)*100));
                        grand_total_vs_q4 = ((grand_total_count_q4 === 0 || grand_total_quota_q4 === 0) ? 0 : Math.round((grand_total_count_q4/grand_total_quota_q4)*100));
    
                        grand_total_count += total_count;
                        grand_total_quota += total_quota;
                        grand_total_vs = ((grand_total_count === 0 || grand_total_quota === 0) ? 0 : Math.round((grand_total_count/grand_total_quota)*100));

                        // to add a sub total row when recruitment team is changed
                        if ((groupOrder !== element.supervisorCode && groupOrder !== element.recruiter_code) || isLastElement){

                            return (
                                <>      
                                    <tr key={index}>
                                        <td className="table-data">{element.recruiter_name}</td>
                                        <td className="table-data">{element.country_name}</td>                               

                                        <td className="table-data">{q1_count}</td>
                                        <td className="table-data">{q1_quota}</td>
                                        <td className="table-data">{q1_vs}%</td>

                                        <td className="table-data">{q2_count}</td>
                                        <td className="table-data">{q2_quota}</td>
                                        <td className="table-data">{q2_vs}%</td>

                                        <td className="table-data">{q3_count}</td>
                                        <td className="table-data">{q3_quota}</td>
                                        <td className="table-data">{q3_vs}%</td>

                                        <td className="table-data">{q4_count}</td>
                                        <td className="table-data">{q4_quota}</td>
                                        <td className="table-data">{q4_vs}%</td>

                                        <td className="table-data">{total_count}</td>
                                        <td className="table-data">{total_quota}</td>
                                        <td className="table-data">{total_vs}%</td>
                                    </tr>
                                    <tr className="sub-group">
                                        <td className="table-data4" colSpan="2">Sub-Total</td>                             

                                        <td className="table-data4">{sub_q1_count}</td>
                                        <td className="table-data4">{sub_q1_quota}</td>
                                        <td className="table-data4">{sub_q1_vs}%</td>

                                        <td className="table-data4">{sub_q2_count}</td>
                                        <td className="table-data4">{sub_q2_quota}</td>
                                        <td className="table-data4">{sub_q2_vs}%</td>

                                        <td className="table-data4">{sub_q3_count}</td>
                                        <td className="table-data4">{sub_q3_quota}</td>
                                        <td className="table-data4">{sub_q3_vs}%</td>

                                        <td className="table-data4">{sub_q4_count}</td>
                                        <td className="table-data4">{sub_q4_quota}</td>
                                        <td className="table-data4">{sub_q4_vs}%</td>

                                        <td className="table-data4">{sub_total_count}</td>
                                        <td className="table-data4">{sub_total_quota}</td>
                                        <td className="table-data4">{sub_total_vs}%</td>
                                    </tr>
                                    {resetSubData()}
                                </>    
                            )
                        }

                        else {
                            return (
                                <tr key={index}>
                                    <td className="table-data">{element.recruiter_name}</td>
                                    <td className="table-data">{element.country_name}</td>                               
    
                                    <td className="table-data">{q1_count}</td>
                                    <td className="table-data">{q1_quota}</td>
                                    <td className="table-data">{q1_vs}%</td>
    
                                    <td className="table-data">{q2_count}</td>
                                    <td className="table-data">{q2_quota}</td>
                                    <td className="table-data">{q2_vs}%</td>
    
                                    <td className="table-data">{q3_count}</td>
                                    <td className="table-data">{q3_quota}</td>
                                    <td className="table-data">{q3_vs}%</td>
    
                                    <td className="table-data">{q4_count}</td>
                                    <td className="table-data">{q4_quota}</td>
                                    <td className="table-data">{q4_vs}%</td>
    
                                    <td className="table-data">{total_count}</td>
                                    <td className="table-data">{total_quota}</td>
                                    <td className="table-data">{total_vs}%</td>
                                </tr>
                            )    
                        }
                    })}
                    <tr>
                        <td className="table-data2">Grand Total</td>
                        <td className="table-data2">All Region</td>
                        <td className="table-data2">{grand_total_count_q1}</td>
                        <td className="table-data2">{grand_total_quota_q1}</td>
                        <td className="table-data2">{grand_total_vs_q1}%</td>
                        <td className="table-data2">{grand_total_count_q2}</td>
                        <td className="table-data2">{grand_total_quota_q2}</td>
                        <td className="table-data2">{grand_total_vs_q2}%</td>
                        <td className="table-data2">{grand_total_count_q3}</td>
                        <td className="table-data2">{grand_total_quota_q3}</td>
                        <td className="table-data2">{grand_total_vs_q3}%</td>
                        <td className="table-data2">{grand_total_count_q4}</td>
                        <td className="table-data2">{grand_total_quota_q4}</td>
                        <td className="table-data2">{grand_total_vs_q4}%</td>
                        <td className="table-data2">{grand_total_count}</td>
                        <td className="table-data2">{grand_total_quota}</td>
                        <td className="table-data2">{grand_total_vs}%</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default RecruiterPerformanceYearlyTable;