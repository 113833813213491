import React, { useState } from 'react';

const CustomInput = ({id, value, onChange, disabled }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <input style={{width: '3rem'}}
        id={id}
        type='number'
        value={inputValue}
        onChange={handleInputChange}
        disabled={disabled}
    />
  );
};

export default CustomInput;