import {React, useEffect, useRef} from "react";
import Card from 'react-bootstrap/Card';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';


function LoginForm(){

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  useEffect(() => {
    const savedSessionData = Cookies.get('sessionData');
    if (savedSessionData) {
      const data = JSON.parse(savedSessionData);
      if (data['isViewAllowed'] === 1) {
        navigateRef.current('/');
      }
    } else {
      localStorage.removeItem('token')
      navigateRef.current('/login');
    }
  }, []);

  const handleSaveSessionData = (data) => {
    const dataToSave = JSON.stringify(data);
    Cookies.set('sessionData', dataToSave);
  };
  
  let noti = "";
  const notify = () => toast.error(noti);

  const handleLogin = async (e) => {
    e.preventDefault()
    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;

    if (username === '' || password === '' || username === null || password === null){
      noti = "Username or Password cannot be empty!";
      notify();
    }
    else {
      const result = await login(username, password);
      if (result !== null && result.success){
        const data = result['key'];
        if (data.isViewAllowed === 1){
          handleSaveSessionData(data);
          navigate('/');
        }
        else{
          noti = "You cannot access this page!"
          notify();
        }
      }
      else{
        noti = "Wrong Username or Password!"
        notify();
      }
    }
  };

  async function login(username,password){
    const data = {username, password};

    const url = process.env.REACT_APP_API + `/loginUser`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        // if (!response.ok) {
        //     throw new Error(`HTTP error! Status: ${response.status}`);
        // }
        const responseData = await response.json();
        localStorage.setItem("token", responseData.key.token);
        return responseData;
    } catch (error) {
        // console.error('Error:', error);
        return null;
    }
  }

  return (
      <Card className="custom" style={{minWidth: '30%'}}>
        <ToastContainer />
        <Card.Body style={{textAlign:'center'}}>
          <form>
            <div>
              <h3 style={{color:'white', paddingTop:'20px', fontFamily:'sans-serif'}}>Login</h3>
            </div>
            
            <div className="d-flex" style={{flexDirection:'column', alignItems:'center', gap:'2vh', padding:'3vh 0'}}>
              <input type='text' id="username" placeholder="Username" style={{fontSize:'16px', width:'80%', borderRadius:'5px', textAlign:'center', padding: '5px'}} required/>
              <input type='password' id="password" placeholder="Password" style={{fontSize:'15px', width:'80%', borderRadius:'5px', textAlign:'center', padding: '5px'}} required/>
              <Button type="submit" className="btn-custom" onClick={handleLogin}>Login</Button>
            </div>
            <i className="text-white" style={{fontSize:'13px'}}>Note: Please use your SEMS account. Only authorized users can access this page.</i>
          </form>
        </Card.Body>
      </Card>
  );
}

export default LoginForm;