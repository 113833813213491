import '../../src/App.css';
import {React} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import CustomSelect from './CustomSelect';
import Card from 'react-bootstrap/Card';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

function ManageIntervalTab(props){

    const navigate = useNavigate();
  
    let noti = "";
    const notifySuccess = () => toast.success(noti);
    const notifyError = () => toast.error(noti);

    let data = props.data;
    let activeInterval = props.activeInterval;

    const handleUpdate = async() => {
        const id = (document.getElementById("interval")).value;

        try{
            const result = await postData(id, "update");
            if(result.data.success){
                noti = "Successfully Updated!";
                notifySuccess();
            }
            else{
                noti = "Update Failed, Please Inform MIS!";
                notifyError();
            }
        }
        catch{
            noti = "Update Failed, Please Inform MIS!";
            notifyError();
        }
    };

    async function postData(bodyData) {
        const url = process.env.REACT_APP_API + `/updateIntervals`;
        const configs = {
          headers:{
            'Authorization': 'Bearer '+localStorage.getItem("token"),
            'Content-Type': 'application/json',   
          }
        };
        try {
          const response = await axios.put(url, {id:bodyData}, configs);
          return response;
        } catch (error) {
          if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
            Cookies.remove('sessionData');
            localStorage.removeItem('token')
            navigate('/login')
            return;
          }
          console.error("Error:", error);
        }
    }

    /*
    async function postData(data) {
        const url = configs.url + `/updateIntervals`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Authorization': 'Bearer '+localStorage.getItem("token"),
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({id:data}),
              });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }
    */
       
    return (
        <div>
            <ToastContainer />
            <div style={{marginTop:'10px'}}>
                <Card className="dark" style={{width:"20%"}}>
                    <div className="d-flex flex-wrap" style={{padding:"10px", display:"inline"}}>
                        <div className="col" style={{display:"inline-flex", margin:"auto"}}>
                            <div style={{paddingRight:"10px", color:"white"}}>
                                Time (min):
                            </div>
                            <div>
                                {activeInterval && <CustomSelect type={'noOthers'} id={'interval'} value={activeInterval} data={data} onChange = {() => {}} onUpdate={() => {}}/>}
                            </div>
                        </div>
                        <div className="col" style={{textAlign:"center"}}>
                            <Button variant="submit" onClick={handleUpdate} style={{backgroundColor:"#abc7ff !important", opacity:"1 !important"}}>Set</Button>
                        </div>
                    </div>
                </Card>
            </div>
        </div>      
    )
}
export default ManageIntervalTab;