import '../../src/App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function CourseEnrollmentTable(props) {

  let data = props.tableData;

  let total_q1 = 0;
  let total_q2 = 0;
  let total_q3 = 0;
  let total_q4 = 0;
  let grand_total = 0;

  return (
    <div className='tableWrap'>
        <table>
            <thead>
                <tr>
                    <th className="table-header" style={{width:'10%'}}>Course Code</th>
                    <th className="table-header" style={{width:'45%'}}>Course Name</th>
                    <th className="table-header">Q1 Count</th>
                    <th className="table-header">Q2 Count</th>
                    <th className="table-header">Q3 Count</th>
                    <th className="table-header">Q4 Count</th>
                    <th className="table-header">Total</th>
                </tr>
            </thead>
            
            <tbody>
                {data.map((element, index) => {
                    total_q1 += element.q1_count;
                    total_q2 += element.q2_count;
                    total_q3 += element.q3_count;
                    total_q4 += element.q4_count;
                    grand_total += element.total;

                    return (
                        <tr key={index}>
                            <td className="table-data1">{element.course_code}</td>
                            <td className="table-data">{element.course_name}</td>                               
                            <td className="table-data">{element.q1_count}</td> 
                            <td className="table-data">{element.q2_count}</td>
                            <td className="table-data">{element.q3_count}</td>
                            <td className="table-data">{element.q4_count}</td>
                            <td className="table-data">{element.total}</td>  
                        </tr>
                    )
                })}
                <tr>
                    <td className="table-data2"></td>
                    <td className="table-data2">Total</td>
                    <td className="table-data2">{total_q1}</td>
                    <td className="table-data2">{total_q2}</td>
                    <td className="table-data2">{total_q3}</td>
                    <td className="table-data2">{total_q4}</td>
                    <td className="table-data2">{grand_total}</td>
                </tr>
            </tbody>
        </table>
    </div>
)
}

export default CourseEnrollmentTable;
