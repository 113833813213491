import {React, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

function LogoutPage() {

    const navigate = useNavigate();

    useEffect(() => {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
    }, [navigate]);
    
    return (
        <>
        </>
    );
}

export default LogoutPage;