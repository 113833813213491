import '../../src/App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function AgentPerformanceComparativeAnalysisTable(props){

    let year = props.year;
    let year1 = year-1;
    let year2 = year-2;

    let data = props.tableData;

    let total_applied_year = 0;
    let total_enrolled_year = 0;
    let total_diff_year = 0;

    let total_applied_year1 = 0;
    let total_enrolled_year1 = 0;
    let total_diff_year1 = 0;

    let total_applied_year2 = 0;
    let total_enrolled_year2 = 0;
    let total_diff_year2 = 0;
    
    return (
        <div>
            <div className='tableWrap'>
                <table>
                    <thead>
                        <tr>
                            <th className="table-header" rowSpan="3" colSpan="1" style={{width:'8%'}}>Region</th>
                            <th className="table-header" rowSpan="3" colSpan="1" style={{width:'30%'}}>Agent</th>
                            <th className="table-header" rowSpan="3" colSpan="1" style={{width:'10%'}}>Chinese Name</th>
                            <th className="table-header" rowSpan="1" colSpan="3">{year2}</th>
                            <th className="table-header" rowSpan="1" colSpan="3">{year1}</th>
                            <th className="table-header" rowSpan="1" colSpan="3">{year}</th>
                        </tr>
                        <tr>
                            {Array.from({ length: 3 }, (_, index) => (
                                <React.Fragment key={index}>
                                    <th className="table-header">Applied</th>
                                    <th className="table-header">Enrolled</th>
                                    <th className="table-header">Difference</th>
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {data.map((element, index) => {

                            total_applied_year += element.application_count_current;
                            total_enrolled_year += element.enrollment_count_current;
                            total_diff_year += element.currentYear_diff;
                            
                            total_applied_year1 += element.application_count_previous1Year;
                            total_enrolled_year1 += element.enrollment_count_previous1Year;
                            total_diff_year1 += element.previous1Year_diff;
                            
                            total_applied_year2 += element.application_count_previous2Year;
                            total_enrolled_year2 += element.enrollment_count_previous2Year;
                            total_diff_year2 += element.previous2Year_diff;

                            return (
                                <tr key={index}>
                                    <td className="table-data">{element.region_name}</td> 
                                    <td className="table-data1">{element.agent_name}</td>
                                    <td className="table-data1">{element.agent_chinese_name}</td>

                                    <td className="table-data">{element.application_count_previous2Year}</td>
                                    <td className="table-data">{element.enrollment_count_previous2Year}</td>
                                    <td className="table-data">{element.previous2Year_diff}</td>

                                    <td className="table-data">{element.application_count_previous1Year}</td>
                                    <td className="table-data">{element.enrollment_count_previous1Year}</td>
                                    <td className="table-data">{element.previous1Year_diff}</td>

                                    <td className="table-data">{element.application_count_current}</td>
                                    <td className="table-data">{element.enrollment_count_current}</td>
                                    <td className="table-data">{element.currentYear_diff}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td className="table-data2" colSpan="3">Grand Total</td>
                            <td className="table-data2">{total_applied_year2}</td>
                            <td className="table-data2">{total_enrolled_year2}</td>
                            <td className="table-data2">{total_diff_year2}</td>
                            <td className="table-data2">{total_applied_year1}</td>
                            <td className="table-data2">{total_enrolled_year1}</td>
                            <td className="table-data2">{total_diff_year1}</td>
                            <td className="table-data2">{total_applied_year}</td>
                            <td className="table-data2">{total_enrolled_year}</td>
                            <td className="table-data2">{total_diff_year}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        
    )
}
export default AgentPerformanceComparativeAnalysisTable;