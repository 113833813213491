import '../../src/App.css';
import {React} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import CustomSelect from './CustomSelect';
import Card from 'react-bootstrap/Card';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

function ManageAccessTable(props){

    const navigate = useNavigate();
  
    let noti = "";
    const notifySuccess = () => toast.success(noti);
    const notifyError = () => toast.error(noti);

    let data = props.tableData; 
    let data1 = props.recruitHeads;

    const accessValues = [
        {key: '0', value: "No"},
        {key: '1', value: "Allow"}
    ];

    const handleCreate = async() => {
        const dataBody = [];
        let isViewAllowed = 0;
        let isEditAllowed = 0;

        if((document.getElementById(`viewAccess`)).value === '1'){
            isViewAllowed = 1;
        }
        if((document.getElementById(`manageAccess`)).value === '1'){
            isEditAllowed = 1;
        }

        dataBody.push({
            empNo: (document.getElementById(`user`)).value,
            deptNo: "",
            isViewAllowed: isViewAllowed,
            isEditAllowed: isEditAllowed,
        });
        try{
            const result = await postData(dataBody);
            if(result.data.success){
                window.location.href="/manage_access";
                // noti = "Successfully Created!";
                // notifySuccess();
            }
            else{
                noti = "Cannot Create, Please Inform MIS!";
                notifyError();
            }
        }
        catch{
            noti = "Cannot Create, Please Inform MIS!";
            notifyError();
        }
    };

    const handleUpdate = async(emp_no) => {
        const dataBody = [];
        let isViewAllowed = 0;
        let isEditAllowed = 0;

        if((document.getElementById(`${emp_no}_viewAccess`)).value === '1'){
            isViewAllowed = 1;
        }
        if((document.getElementById(`${emp_no}_manageAccess`)).value === '1'){
            isEditAllowed = 1;
        }
        dataBody.push({
            empNo: emp_no,
            isViewAllowed: isViewAllowed,
            isEditAllowed: isEditAllowed,
        });

        try{
            const result = await postData(dataBody);
            if(result.data.success){
                noti = "Successfully Updated!";
                notifySuccess();
            }
            else{
                noti = "Update Failed, Please Inform MIS!";
                notifyError();
            }
        }
        catch{
            noti = "Update Failed, Please Inform MIS!";
            notifyError();
        }
    };

    const handleDelete = async(emp_no) => {
        try{
            const result = await deleteData(emp_no);
            if(result.data.success){
                noti = "Successfully Deleted!";
                //notifySuccess();
                window.location.href="/manage_access";
            }
            else{
                noti = "Cannot Delete, Please Inform MIS!";
                notifyError();
            }
        }
        catch{
            noti = "Cannot Delete, Please Inform MIS!";
            notifyError();
        }
    };

    async function postData(bodyData) {
        const url = process.env.REACT_APP_API + `/createAuthorizeUser`;
        const configs = {
          headers:{
            'Authorization': 'Bearer '+localStorage.getItem("token"),
            'Content-Type': 'application/json',   
          }
        };
        try {
          const response = await axios.post(url, {user:bodyData}, configs);
          return response;
        } catch (error) {
          if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
            Cookies.remove('sessionData');
            localStorage.removeItem('token')
            navigate('/login')
            return;
          }
          console.error("Error:", error);
        }
    }

    async function deleteData(emp_no) {
        const url = process.env.REACT_APP_API + `/deleteAuthorizedUser`;
        const configs = {
          headers:{
            'Authorization': 'Bearer '+localStorage.getItem("token"),
            'Content-Type': 'application/json',   
          }
        };
        try {
          const response = await axios.post(url, { empNo: emp_no }, configs);
          return response;
        } catch (error) {
          if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
            Cookies.remove('sessionData');
            localStorage.removeItem('token')
            navigate('/login')
            return;
          }
          console.error("Error:", error);
        }
    }

    /*
    async function postData(data, action) {
        if(action === "update"){
            const url = process.env.REACT_APP_API + `/createAuthorizeUser`;
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                      'Authorization': 'Bearer '+localStorage.getItem("token"),
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({user:data}),
                  });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const responseData = await response.json();
                return responseData;
            } catch (error) {
                console.error('Error:', error);
                return null;
            }
        }
        else if (action === "create"){
            const url = process.env.REACT_APP_API + `/createAuthorizeUser`;
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                      'Authorization': 'Bearer '+localStorage.getItem("token"),
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({user:data}),
                  });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const responseData = await response.json();
                return responseData;
            } catch (error) {
                console.error('Error:', error);
                return null;
            }
        }
    }

    async function deleteData(emp_no) {
        const url = process.env.REACT_APP_API + `/deleteAuthorizedUser`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Authorization': 'Bearer '+localStorage.getItem("token"),
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({empNo: emp_no}),
              });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }
    */

    // // re-render the table to update all states of input fields easily
    // const [shouldRenderTable, setShouldRenderTable] = useState(true);
    // const destroyAndRecreateTable = () => {
    //     setShouldRenderTable(false); 
    //     setData([]);
    //     setTimeout(() => setShouldRenderTable(true), 0);
    // };
      
    return (
        <div>
            <ToastContainer />
            <div style={{marginTop:'10px'}}>
                <Card className="dark" style={{width:"60%"}}>
                    <div className="d-flex flex-wrap" style={{padding:"10px", display:"inline"}}>
                        <div className="col-3" style={{display:"inline-flex", margin:"auto"}}>
                            <div style={{paddingRight:"10px", color:"white"}}>
                                User:
                            </div>
                            <div>
                                <CustomSelect type={''} id={'user'} value={""} data={data1} onChange = {() => {}} onUpdate={() => {}}/>
                            </div>
                        </div>
                        <div className="col-3" style={{display:"inline-flex", margin:"auto", textAlign:"center"}}>
                            <div style={{paddingRight:"10px", color:"white"}}>
                                View Access:
                            </div>
                            <div>
                                <CustomSelect type={''} id={'viewAccess'} value={'0'} data={accessValues} onChange = {() => {}} onUpdate={() => {}}/>
                            </div>
                        </div>
                        <div className="col-3" style={{display:"inline-flex", margin:"auto", textAlign:"center"}}>
                            <div style={{paddingRight:"10px", color:"white"}}>
                                Manage Access:
                            </div>
                            <div>
                                <CustomSelect type={''} id={'manageAccess'} value={'0'} data={accessValues} onChange = {() => {}} onUpdate={() => {}}/>
                            </div>
                        </div>
                        <div className="col-1" style={{display:"inline-flex", margin:"auto"}}>
                            <Button variant="submit" onClick={handleCreate} style={{backgroundColor:"#abc7ff !important", opacity:"1 !important"}}>Create</Button>
                        </div>
                    </div>
                </Card>

                <br></br>

                <table>
                    <thead>
                        <tr>     
                            <th className="table-header" style={{width:'20%'}}>User</th>
                            <th className="table-header" style={{width:'20%'}}>Department</th>
                            <th className="table-header">View Access</th>
                            <th className="table-header">Manage Access</th>
                            <th className="table-header"></th>
                        </tr>
                    </thead>

                    { <tbody>
                        {data.map((element, index) => {  
                            let canView = '0';
                            let canEdit = '0';
                            if (element.isViewAllowed === 1){
                                canView = '1';
                            }
                            if (element.isEditAllowed === 1){
                                canEdit = '1';
                            }

                            return (
                                <tr key={index}>
                                    <td className="table-data" id={element.emp_no}>{element.empName}</td>
                                    <td className="table-data">{element.dept_code}</td>        
                                    <td className="table-data">
                                        <CustomSelect type={''} id={element.emp_no + '_viewAccess'} value={canView} data={accessValues} onChange = {() => {}} onUpdate={() => handleUpdate(element.emp_no)}/>
                                    </td>
                                    <td className="table-data">
                                        <CustomSelect type={''} id={element.emp_no + '_manageAccess'} value={canEdit} data={accessValues} onChange = {() => {}} onUpdate={() => handleUpdate(element.emp_no)}/>
                                    </td>
                                    <td className="table-data">
                                        <Button variant="danger" onClick={() => handleDelete(element.emp_no)}>Delete</Button>
                                    </td>
                                </tr>
                            )
                        })} 

                    </tbody> }
                </table>     
            </div>
        </div>      
    )
}
export default ManageAccessTable;