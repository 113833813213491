import React from "react";
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/App.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import NavMenu from '../components/NavMenu';
import ManageCourseTable from "../components/ManageCourseTable";


function ManageCourse(){

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  useEffect(() => {
    const savedSessionData = Cookies.get('sessionData');
    if (savedSessionData) {
      const data = JSON.parse(savedSessionData);
      if (data['isEditAllowed'] !== 1) {
        navigateRef.current('/access_denied');
      }
    } else {
      navigateRef.current('/login');
    }
  }, []);


  const callbacksRef = useRef(() => callbacks());
  useEffect(() => {
    callbacksRef.current();
  }, []);


  const [data, setData] = useState([]);
  const [courseTypes, setCourseTypes] = useState([]);
  const [courseLvls, setCourseLvls] = useState([]);
  const [selectedCourseType, setCourseType] = useState([]);
  const [selectedCourseLvl, setCourseLvl] = useState([]);
  const [selectedCourseGroup, setCourseGroup] = useState([]);

  const [course, setCourseInfo] = useState(["All Course Types"]);
  const [courseLvl, setCourseLvlInfo] = useState(["All Course Lvls"]);
  const [courseGroup, setCourseGroupInfo] = useState(["All Course Groups"]);


  async function callbacks(){
    await getData("","","");
    await getCourseTypeData();
    await getCourseLvlData();
  }

  const handleCourseTypeSelect = (eventKey) => {
    setCourseType(eventKey);
    getData(eventKey, selectedCourseLvl, selectedCourseGroup);
    let key = parseInt(eventKey);
    if(courseTypes){
      let temp = "All Courses";
      courseTypes.forEach((element, index) => {
        if (key === element['key']){
          temp = element['value'];
        }
      });
      setCourseInfo(temp);
    }
    destroyAndRecreateTable();
  }

  const handleCourseLvlSelect = (eventKey) => {
    setCourseLvl(eventKey);
    getData(selectedCourseType, eventKey, selectedCourseGroup);
    if(courseLvls){
      let temp = "All Course Lvls";
      courseLvls.forEach((element, index) => {
        if (eventKey === element['key']){
          temp = element['value'];
        }
      });
      setCourseLvlInfo(temp);
    }
    destroyAndRecreateTable();
  }

  const handleCourseGroupSelect = (eventKey) => {
    setCourseGroup(eventKey);
    getData(selectedCourseType, selectedCourseLvl, eventKey);
    if (eventKey === "BUSINESS"){
      setCourseGroupInfo("Business");
    }
    else if(eventKey === "ENGLISH"){
      setCourseGroupInfo("English")
    }
    else if(eventKey === "H&T"){
      setCourseGroupInfo("H&T")
    }
    else if(eventKey === "OTHERS"){
      setCourseGroupInfo("All")
    }
    else{
      setCourseGroupInfo("All");
    }
    destroyAndRecreateTable();
  };

  async function getData(selectedCourseType, selectedCourseLvl, selectedCourseGroup) {
    const url = process.env.REACT_APP_API + `/getCourseByList`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {
        courseTypeId: selectedCourseType,
        courseLvl: selectedCourseLvl,
        courseGrp: selectedCourseGroup
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setData(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getCourseTypeData(){
    const url = process.env.REACT_APP_API + `/getCourseTypeByList`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setCourseTypes(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getCourseLvlData(){
    const url = process.env.REACT_APP_API + `/getCourseLvlList`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setCourseLvls(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  /*
  async function getData(selectedCourseType, selectedCourseLvl, selectedCourseGroup) {
    const params = new URLSearchParams({
      courseTypeId: selectedCourseType,
      courseLvl: selectedCourseLvl,
      courseGrp: selectedCourseGroup
    });
    const url = process.env.REACT_APP_API + `/getCourseByList?${params}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }
    });
    const jsonData = await response.json();
    setData(jsonData['key']);
  }

  async function getCourseTypeData() {
    const url = process.env.REACT_APP_API + `/getCourseTypeByList`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }
    });
    const jsonData = await response.json();
    setCourseTypes(jsonData['key']);
  }

  async function getCourseLvlData() {
    const url = process.env.REACT_APP_API + `/getCourseLvlList`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }
    });
    const jsonData = await response.json();
    setCourseLvls(jsonData['key']);
  }
  */

  // re-render the table to update all states of input fields easily
  const [shouldRenderTable, setShouldRenderTable] = useState(true);
  const destroyAndRecreateTable = () => {
    setShouldRenderTable(false); 
    setData([]);
    setTimeout(() => setShouldRenderTable(true), 0);
  };

  return(
      <div>
          <NavMenu />
          <Container fluid>
              <div className="d-flex flex-row">
                      
                <DropdownButton variant="secondary" id="courseTypeSelect" title="Course Type" style={{marginRight:'5px'}} onSelect={handleCourseTypeSelect}>
                  <Dropdown.Item eventKey={''}>All</Dropdown.Item>
                  {courseTypes.map((element, index) => {
                      return (
                          <Dropdown.Item key={index} eventKey={element.key}>{element.value}</Dropdown.Item>
                      )
                  })} 
                </DropdownButton>

                <DropdownButton variant="secondary" id="courseLvlSelect" title="Course Level" style={{marginRight:'5px'}} onSelect={handleCourseLvlSelect}>
                  <Dropdown.Item eventKey={''}>All</Dropdown.Item>
                    {courseLvls.map((element, index) => {
                      return (
                          <Dropdown.Item key={index} eventKey={element.level_code}>{element.long_desc}</Dropdown.Item>
                      )
                    })} 
                </DropdownButton>

                <DropdownButton variant="secondary" id="courseGroupSelect" title="Course Group" style={{marginRight:'5px'}} onSelect={handleCourseGroupSelect}>
                  <Dropdown.Item eventKey={''}>All</Dropdown.Item>
                  <Dropdown.Item eventKey={'BUSINESS'}>Business</Dropdown.Item>
                  <Dropdown.Item eventKey={'ENGLISH'}>English</Dropdown.Item>
                  <Dropdown.Item eventKey={'H&T'}>H&T</Dropdown.Item>
                  <Dropdown.Item eventKey={'OTHERS'}>Others</Dropdown.Item>
                </DropdownButton>

                <span className="badge text-bg-dark" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', opacity:'0.7' }} >
                  <p style={{margin:"auto"}}>Manage Courses - {course} - {courseLvl} - {courseGroup}</p>
                </span>
              </div>

              {shouldRenderTable && <ManageCourseTable tableData = {data} courseTypes = {courseTypes}/>}
          </Container>
      </div>
  )
}

export default ManageCourse;