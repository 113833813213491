import React from 'react';
import Container from 'react-bootstrap/Container';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/App.css';
import LoginForm from '../components/LoginForm';

function LoginPage() {
  return (
    <div>
        <Container fluid>
          <div className="d-flex flex-row" style={{justifyContent: 'center', alignItems: 'center', height:'100%' }}>
            <LoginForm />
          </div>
        </Container>
    </div>
  );
}

export default LoginPage;