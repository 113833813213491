import '../../src/App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function AgentPerformanceTable(props) {

  let data = props.tableData; 

  let year = props.year;
  let year1 = year-1;

  let grand_total_totalApplicant = 0;
  let grand_total_enrolled = 0;
  let grand_total_applicationYearPrev = 0;
  let grand_total_totalEnrol = 0;
  let grand_total_pendingEnrol = 0;

  return (
    <div className='tableWrap'>
        <table>
            <thead>
                <tr>
                    <th className="table-header" style={{width: '8%'}}>Region</th>
                    <th className="table-header" style={{width: '40%'}}>Agent</th>
                    <th className="table-header">Recruiter</th>
                    <th className="table-header">Total Applicant</th>
                    <th className="table-header">Enrolled</th>
                    <th className="table-header">From {year1} Applicant</th>
                    <th className="table-header">Total Enrolment</th>
                    <th className="table-header">Pending Enrolment</th>
                </tr>
            </thead>
            
            <tbody>
                {data.map((element, index) => {

                    let totalApplicant = element.application_year_current;
                    let enrolled = element.enroll_year_current - element.application_year_previous;
                    let applicationYearPrev = element.application_year_previous;
                    let totalEnrol = element.enroll_year_current;
                    let pendingEnrol = totalApplicant - totalEnrol;

                    grand_total_totalApplicant += totalApplicant;
                    grand_total_enrolled += enrolled;
                    grand_total_applicationYearPrev += applicationYearPrev;
                    grand_total_totalEnrol += totalEnrol;
                    grand_total_pendingEnrol += pendingEnrol;

                    return (
                        <tr key={index}>
                            <td className="table-data1">{element.region_name}</td>
                            <td className="table-data">{element.agent_name}</td>                               
                            <td className="table-data">{element.recruiter_name}</td>
                            <td className="table-data">{totalApplicant}</td>
                            <td className="table-data">{enrolled}</td>
                            <td className="table-data">{applicationYearPrev}</td>
                            <td className="table-data">{totalEnrol}</td>
                            <td className="table-data">{pendingEnrol}</td>
                        </tr>
                    )
                })}
                <tr>
                    <td className="table-data2"></td>
                    <td className="table-data2"></td>
                    <td className="table-data2">Grand Total</td>
                    <td className="table-data2">{grand_total_totalApplicant}</td>
                    <td className="table-data2">{grand_total_enrolled}</td>
                    <td className="table-data2">{grand_total_applicationYearPrev}</td>
                    <td className="table-data2">{grand_total_totalEnrol}</td>
                    <td className="table-data2">{grand_total_pendingEnrol}</td>
                </tr>
            </tbody>
        </table>
    </div>
)
}

export default AgentPerformanceTable;
