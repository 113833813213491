import React from "react";
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/App.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import NavMenu from '../components/NavMenu';
import ManageQuotaTable from "../components/ManageQuotaTable";


function ManageQuota(){

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  useEffect(() => {
    const savedSessionData = Cookies.get('sessionData');
    if (savedSessionData) {
      const data = JSON.parse(savedSessionData);
      if (data['isEditAllowed'] !== 1) {
        navigateRef.current('/access_denied');
      }
    } else {
      navigateRef.current('/login');
    }
  }, []);


  const callbacksRef = useRef(() => callbacks());
  useEffect(() => {
    callbacksRef.current();
  }, []);


  let currYear = new Date().getFullYear();
  let year1 = currYear-1;
  let year2 = currYear-2;

  const [region, setRegionInfo] = useState(["All Regions"]);
  const [course, setCourseInfo] = useState(["All Courses"]);

  const [data, setData] = useState([]);
  const [selectedYear, setYear] = useState([]);
  const [selectedRegion, setRegion] = useState([]);
  const [selectedCourseType, setCourseType] = useState([]);
  const [courseTypes, setCourseTypes] = useState([]);

  
  async function callbacks(){
    setYear(currYear);
    setRegion("");
    setCourseType("");
    await getData(currYear,"","");
    await getCourseTypeData();
  }

  const handleYearSelect = (eventKey) => {
    setYear(eventKey);
    getData(eventKey, selectedRegion, selectedCourseType);
    destroyAndRecreateTable();
  };

  const handleRegionSelect = (eventKey) => {
    setRegion(eventKey);
    getData(selectedYear, eventKey, selectedCourseType);
    if (eventKey === "0011"){
      setRegionInfo("China");
    }
    else if(eventKey === "0010"){
      setRegionInfo("SG/ ASEAN")
    }
    else{
      setRegionInfo("All Regions");
    }
    destroyAndRecreateTable();
  }

  const handleCourseTypeSelect = (eventKey) => {
    setCourseType(eventKey);
    getData(selectedYear, selectedRegion, eventKey);
    let key = parseInt(eventKey);
    if(courseTypes){
      let temp = "All Courses";
      courseTypes.forEach((element, index) => {
        if (key === element['key']){
          temp = element['value'];
        }
      });
      setCourseInfo(temp);
    }
    destroyAndRecreateTable();
  }

  async function getCourseTypeData(){
    const url = process.env.REACT_APP_API + `/getCourseTypeByList`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setCourseTypes(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getData(selectedYear, selectedRegion, selectedCourseType) {
    const url = process.env.REACT_APP_API + `/getNewPerformance`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {
        year: selectedYear,
        type: 'enrollment',
        recTeam: selectedRegion,
        courseTypeId: selectedCourseType
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setData(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  /*
  async function getCourseTypeData() {
    const url = process.env.REACT_APP_API + `/getCourseTypeByList`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }
    });
    const jsonData = await response.json();
    setCourseTypes(jsonData['key']);
  }

  async function getData(selectedYear, selectedRegion, selectedCourseType) {
    const params = new URLSearchParams({
      year: selectedYear,
      type: 'Enrollment',
      recTeam: selectedRegion,
      courseTypeId: selectedCourseType
    });
    const url = process.env.REACT_APP_API + `/getNewPerformance?${params}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }
    });
    const jsonData = await response.json();
    setData(jsonData['key']);
  }
  */

  // re-render the table
  const [shouldRenderTable, setShouldRenderTable] = useState(true);
  const destroyAndRecreateTable = () => {
    setShouldRenderTable(false); 
    setData([]);
    setTimeout(() => setShouldRenderTable(true), 0);
  };

  return(
      <div>
          <NavMenu />
          <Container fluid>
              <div className="d-flex flex-row">
                      
                <DropdownButton variant="secondary" id="yearSelect" title="Year" style={{marginRight:'5px'}} onSelect={handleYearSelect}>
                  <Dropdown.Item eventKey={currYear}>{currYear}</Dropdown.Item>
                  <Dropdown.Item eventKey={year1}>{year1}</Dropdown.Item>
                  <Dropdown.Item eventKey={year2}>{year2}</Dropdown.Item>
                </DropdownButton>

                <DropdownButton variant="secondary" id="regionSelect" title="Region" style={{marginRight:'5px'}} onSelect={handleRegionSelect}>
                  <Dropdown.Item eventKey={''}>All</Dropdown.Item>
                  <Dropdown.Item eventKey={'0011'}>China</Dropdown.Item>
                  <Dropdown.Item eventKey={'0010'}>SG/ASEAN</Dropdown.Item>
                </DropdownButton>

                <DropdownButton variant="secondary" id="courseTypeSelect" title="Course Type" style={{marginRight:'5px'}} onSelect={handleCourseTypeSelect}>
                  <Dropdown.Item eventKey={''}>All</Dropdown.Item>
                  {courseTypes.map((element, index) => {
                      return (
                          <Dropdown.Item key={index} eventKey={element.key}>{element.value}</Dropdown.Item>
                      )
                  })} 
                </DropdownButton>

                <span className="badge text-bg-dark" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', opacity:'0.7' }} >
                  <p style={{margin:"auto"}}>Manage Quota - {selectedYear} - {region} - {course}</p>
                </span>
              </div>

              {shouldRenderTable && <ManageQuotaTable tableData = {data} year = {selectedYear}/>}
          </Container>
      </div>
  )

}

export default ManageQuota;