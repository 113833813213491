import React from "react";
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/App.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import NavMenu from '../components/NavMenu';
import ManageRecruiterTable from "../components/ManageRecruiterTable";

function ManageRecruiter(){

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  useEffect(() => {
    const savedSessionData = Cookies.get('sessionData');
    if (savedSessionData) {
      const data = JSON.parse(savedSessionData);
      if (data['isEditAllowed'] !== 1) {
        navigateRef.current('/access_denied');
      }
    } else {
      navigateRef.current('/login');
    }
  }, []);


  const callbacksRef = useRef(() => callbacks());
  useEffect(() => {
    callbacksRef.current();
  }, []);

  const [recruiterHeads, setRecruiterHeads] = useState([]);
  const [recruitmentTeams, setRecruitmentTeams] = useState([]);
  const [territories, setTerritories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [data, setData] = useState([]);
  const [selectedRecTeam, setRecTeam] = useState([]);
  const [region, setRegionInfo] = useState(["All Regions"]);


  async function callbacks(){
    await getRecruitHeadsData();
    await getRecruitmentTeamData();
    await getTerritoriesData();
    await getCountriesData();
    await getRegionsData();
    await getData(selectedRecTeam);
  }

  const handleRecTeamSelect = (eventKey) => {
    setRecTeam(eventKey);
    getData(eventKey);
    if (eventKey === "0011"){
      setRegionInfo("China");
    }
    else if(eventKey === "0010"){
      setRegionInfo("SG/ ASEAN")
    }
    else{
      setRegionInfo("All Regions");
    }
    destroyAndRecreateTable();
  }

  async function getRecruitHeadsData() {
    const url = process.env.REACT_APP_API + `/getRecruiterHeads`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setRecruiterHeads(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getRecruitmentTeamData() {
    const url = process.env.REACT_APP_API + `/getRecruitmentTeam`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setRecruitmentTeams(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getTerritoriesData() {
    const url = process.env.REACT_APP_API + `/getActiveTerritoryList`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setTerritories(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getCountriesData() {
    const url = process.env.REACT_APP_API + `/getCountryList`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',     
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setCountries(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getRegionsData() {
    const url = process.env.REACT_APP_API + `/getRegionList`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setRegions(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getData(selectedRecTeam) {
    const url = process.env.REACT_APP_API + `/getRecruiters`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {
        recTeam: selectedRecTeam
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setData(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  /*
  async function getData(selectedRecTeam) {
    const params = new URLSearchParams({
      recTeam: selectedRecTeam
    });
    const url = process.env.REACT_APP_API + `/getRecruiters?${params}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }
    });
    const jsonData = await response.json();
    setData(jsonData['key']);
  }
  */

  // re-render the table
  const [shouldRenderTable, setShouldRenderTable] = useState(true);
  const destroyAndRecreateTable = () => {
    setShouldRenderTable(false); 
    setData([]);
    setTimeout(() => setShouldRenderTable(true), 0);
  };

  return(
      <div>
          <NavMenu />
          <Container fluid>
              <div className="d-flex flex-row">
                <DropdownButton variant="secondary" id="territorySelect" title="Recruitment Team" style={{marginRight:'5px'}} onSelect={handleRecTeamSelect}>
                  <Dropdown.Item eventKey={''}>All</Dropdown.Item>
                  <Dropdown.Item eventKey={'0011'}>China</Dropdown.Item>
                  <Dropdown.Item eventKey={'0010'}>SG/ASEAN</Dropdown.Item>
                </DropdownButton>
                <span className="badge text-bg-dark" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', opacity:'0.7' }} >
                  <p style={{margin:"auto"}}>Manage Recruiters - {region}</p>
                </span>
              </div>

              {shouldRenderTable && <ManageRecruiterTable 
                                      tableData = {data} 
                                      recruiterHeads = {recruiterHeads} 
                                      recruitmentTeams = {recruitmentTeams}
                                      territories = {territories}
                                      countries = {countries}
                                      regions = {regions} />}
          </Container>
      </div>
  )

}

export default ManageRecruiter;